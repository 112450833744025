import {
  BiLogoFacebook,
  BiLogoLinkedinSquare,
  BiLogoTwitter,
} from "react-icons/bi";
import Footerlink from "./Footerlink";
import { informationalLink, quickLink } from "../constants";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black text-white text-center md:text-left">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-[5%] gap-10 w-[90%] leading-7 mx-auto ">
        <div className="flex lg:block justify-center flex-col items-center mt-6 md:mt-0">
          <Link to="/">
            <img
              className="h-[55px] object-cover"
              src="/logo.svg"
              alt="Logo"
              decoding="async"
            />
          </Link>
          <p className="my-6">
            Blueberry Technologies Pvt. Ltd. is a Design Service Startup
            offering Solution & Service in niche areas of ASIC/SoC, Embedded
            Product Engineering supported by Machine Learning, Industrial IoT
            and Artificial Intelligence.
          </p>
          <div className="flex gap-2 text-2xl">
            <Footerlink link="https://www.facebook.com/people/Blueberry-Semiconductors/100067971404917/">
              <BiLogoFacebook />
            </Footerlink>
            <Footerlink link="https://twitter.com/i/flow/login?redirect_after_login=%2Fblueberry_semi">
              <BiLogoTwitter />
            </Footerlink>
            <Footerlink link="https://www.linkedin.com/company/blueberry-semiconductors-pvt-ltd/">
              <BiLogoLinkedinSquare />
            </Footerlink>
          </div>
        </div>
        <div>
          <p className="font-heading mb-6 text-2xl">Quick link</p>
          <ul className="space-y-1">
            {quickLink.map((link) => (
              <li key={link.text}>
                <Footerlink link={link.path}>{link.text}</Footerlink>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <p className="font-heading mb-6 text-2xl">Informational link</p>
          <ul className="space-y-1">
            {informationalLink.map((link) => (
              <li key={link.text}>
                <Footerlink link={link.path}>{link.text}</Footerlink>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <p className="font-heading mb-6 text-2xl">Contact info</p>
          <p>
            13/36, 3rd Cross, Koramangala 6th Block, Venkat Reddy Layout, 80ft
            Road, Nr. Sony World Signal, Bengaluru, Karnataka – 560 095
          </p>
          <p>info@blueberry.tech</p>
          <p>080-48663700</p>
        </div>
      </div>
      <div className="border-t border-t-ftbdr text-white">
        <div className="px-[5%] py-5 gap-10 w-[90%] leading-7 mx-auto flex justify-between">
          <p>
            Copyright © {new Date().getFullYear()} Blueberry Technologies
            Private Limited
          </p>
          <p>Powered by DeveloperOnRent</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
