import { Link } from "react-router-dom";

type Props = { title: string; url: string };

const SingleJobModelBox = ({ title, url }: Props) => {
  return (
    <div className="w-[253px] h-[155px] shadow-md bg-white flex flex-col justify-between p-7">
      <h2 className="font-heading text-[21px] md:text-[22px] font-bold">
        {title}
      </h2>
      <Link to={`/careers/jobs/vlsi/${url}`}>
        <p className="uppercase text-blue-500 text-[13px] font-medium hover:underline cursor-pointer">
          APPLY NOW »
        </p>
      </Link>
    </div>
  );
};

export default SingleJobModelBox;
