import PageHeaders from "../components/PageHeaders";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Testimonial from "../components/Testimonial";
import { testimonialsData } from "../constants";

type Props = {};

const TestimonialPage = (props: Props) => {
  return (
    <>
      <Navbar />
      <div>
        <PageHeaders
          url="/assets/4-1.jpeg"
          title="Case Studies"
          desc={
            "Fun-filled learning for engineers in an informal, flexible work environment"
          }
        />
        <div className="max-w-[1200px] min-h-[410px] m-auto flex flex-col gap-10 justify-between lg:flex-row py-20">
          <div className="w-[95%] lg:w-[360px] flex flex-col gap-3 p-3">
            <p className="text-[36px] text-center lg:text-left lg:text-[48px] leading-10 font-medium font-heading">
              Employees are true assets of Blueberry
            </p>
            <p className="font-montserrat text-center lg:text-left">
              We firmly believe, all young engineers in India are talented. They
              just need right exposure, training and guidance.
            </p>
          </div>
          <div className="w-[95%] lg:w-[654px] flex flex-col gap-2 px-5">
            <img
              src="/double-quotes.png"
              className="w-[24px] h-[24px]"
              alt="left double quotation mark"
            />
            <p className="text-[16px] text-center lg:text-left lg:text-[20px] leading-10 font-montserrat">
              Blueberry Semiconductors Pvt. Ltd. is a great place to work
              because : Family friendly, flexible work schedules, suggestions
              for changes are received openly, easy to implement changes, great
              co-workers, friendly environment, lots of opportunities, senior
              staff seems to really care that employees are happy and motivated.
              I am proud to tell people I work here.
            </p>
            <div className="flex gap-4 mt-5">
              <img
                src="/assets/testimonials/Guruprasad.jpeg"
                alt="avatar"
                className="w-[40px] h-[40px] rounded-full"
              />
              <div className="flex flex-col gap-0">
                <p className="font-semibold">Guru Prasad</p>
                <p>Verification Lead</p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1200px] m-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 place-items-center gap-5">
          {testimonialsData.map((ele, ind) => (
            <div key={ind} className="w-[95%] sm:w-[340px] h-[340px]">
              <Testimonial data={ele} />
            </div>
          ))}
        </div>
        <PageHeaders
          url="/assets/6-1.jpeg"
          title="Case Studies"
          desc={
            "Fun-filled learning for engineers in an informal, flexible work environment"
          }
          buttonTitle={"Apply now"}
        />
        <Footer />
      </div>
    </>
  );
};

export default TestimonialPage;
