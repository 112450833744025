import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import AboutPage from "../Pages/AboutPage";
import CaseStudies from "../Pages/CaseStudies";
import LifeBlueBerry from "../Pages/LifeBlueBerry";
import ServicesPage from "../Pages/ServicesPage";
import CaseStudyDetailsPage from "../Pages/CaseStudyDetailsPage";
import TestimonialPage from "../Pages/TestimonialPage";
import PressAndMedia from "../Pages/PressAndMedia";
import VlsiPage from "../Pages/VlsiPage";
import SoftwareEmbeded from "../Pages/SoftwareEmbeded";
import ContactPage from "../Pages/ContactPage";
import JobDescriptionPage from "../Pages/JobDescriptionPage";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/careers">
        <Route path="life-blueberry" element={<LifeBlueBerry />} />
        <Route path="jobs">
          <Route path="" element={<VlsiPage />} />
          <Route path="vlsi" element={<VlsiPage />} />
          <Route path="vlsi/:jobName" element={<JobDescriptionPage />} />
          <Route path="software-embedded" element={<SoftwareEmbeded />} />
        </Route>
        <Route path="testimonials" element={<TestimonialPage />} />
        <Route path="press-media" element={<PressAndMedia />} />
      </Route>
      <Route path="/services/:id" element={<ServicesPage />} />
      <Route path="/casestudies" element={<CaseStudies />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/casestudies/:studyTitle"
        element={<CaseStudyDetailsPage />}
      />
      <Route path="*" element={<h1>Not Found...</h1>} />
    </Routes>
  );
};
