import PageHeaders from "../components/PageHeaders";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { caseStudies } from "../constants";

type Props = {};

interface IPageData {
  url: string;
  src: string;
  badge: string;
  head: string;
  title: string;
  date: string;
  time: string;
  pageData: Record<string, any>;
}

const CaseStudyDetailsPage = (props: Props) => {
  const location = useLocation();
  const pagePath: IPageData = caseStudies.find(
    (ele) => ele.url == location.pathname
  ) as IPageData;
  const pageData = pagePath?.pageData || {};
  return (
    <>
      <Navbar />
      <div>
        <PageHeaders
          url="/assets/services-background.jpg"
          title={pagePath?.title}
        />
        <section>
          <div className="flex flex-col md:flex-row justify-center items-center md:items-start max-w-[1200px] mx-auto gap-6 p-5 md:p-10 my-10">
            <div className="w-[95%] sm:w-[380px] min-h-[50vh] flex flex-col gap-1">
              <div className="w-full h-fit flex flex-col gap-2 border p-2 px-3 rounded-sm">
                {pageData?.name && (
                  <>
                    <p className="text-xl font-bold">Name</p>
                    <p className="font-montserrat">{pageData.name}</p>
                  </>
                )}
                {pageData?.["DOMAIN / CATEGORY"] && (
                  <>
                    <p className="text-xl font-bold">DOMAIN / CATEGORY</p>
                    <p className="font-montserrat">
                      {pageData["DOMAIN / CATEGORY"]}
                    </p>
                  </>
                )}
                {pageData?.technology && (
                  <>
                    <p className="text-xl font-bold">TECHNOLOGY</p>
                    <p className="font-montserrat">{pageData.technolgy}</p>
                  </>
                )}
                {pageData?.tools && (
                  <>
                    <p className="text-xl font-bold">TOOLS</p>
                    <p className="font-montserrat">{pageData.tools}</p>
                  </>
                )}
                {pageData?.application && (
                  <>
                    <p className="text-xl font-bold">APPLICATION</p>
                    <p className="font-montserrat">{pageData.application}</p>
                  </>
                )}
                {pageData?.language && (
                  <>
                    <p className="text-xl font-bold">Language</p>
                    <p className="font-montserrat">{pageData.language}</p>
                  </>
                )}
                {pageData?.technolgy && (
                  <>
                    <p className="text-xl font-bold">TECHNOLOGY :</p>
                    <p className="font-montserrat">{pageData.technolgy}</p>
                  </>
                )}
              </div>

              <div className="w-full h-[495px]">
                <img
                  src="/assets/add.png"
                  alt="Brochure Image"
                  className="h-[485px] w-full object-cover"
                />
              </div>
            </div>

            {/* Right Section............ */}
            <div className="w-[95%] sm:w-[calc(100%-380px)] flex flex-col gap-7">
              {pageData?.projectDesc && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">PROJECT DESCRIPTION :</span>
                    {pageData?.projectDesc?.desc && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: pageData?.projectDesc?.desc,
                        }}
                      />
                    )}
                  </p>
                  {pageData?.projectDesc?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.projectDesc.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {pageData?.scope && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">SCOPE :</span>
                    {pageData?.scope?.desc && (
                      <span> {pageData?.projectDesc?.desc}</span>
                    )}
                  </p>
                  {pageData?.scope?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.scope.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              <div className="w-full">
                <img
                  className="max-h-[220px]"
                  src={pageData.img}
                  alt={pageData.name || "Image"}
                />
              </div>
              {/* Target process */}
              {pageData?.targetprocess && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">TARGET PROCESS :</span>
                    {pageData?.targetprocess?.desc && (
                      <span> {pageData?.targetprocess?.desc}</span>
                    )}
                  </p>
                  {pageData?.targetprocess?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.targetprocess.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}
              {/* Highlights */}
              {pageData?.highlight && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">HIGHLIGHT :</span>
                    {pageData?.highlight?.desc && (
                      <span> {pageData?.highlight?.desc}</span>
                    )}
                  </p>
                  {pageData?.highlight?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.highlight.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}
              {/* Complexity Overcome */}
              {pageData?.complexityOvr && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Complexity Overcome :</span>
                    {pageData?.complexityOvr?.desc && (
                      <span> {pageData?.complexityOvr?.desc}</span>
                    )}
                  </p>
                  {pageData?.complexityOvr?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.complexityOvr.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Complexity architecture */}
              {pageData?.architecture && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">ARCHITECTURE :</span>
                    {pageData?.architecture?.desc && (
                      <span> {pageData?.architecture?.desc}</span>
                    )}
                  </p>
                  {pageData?.architecture?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.architecture.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* ROLE */}
              {pageData?.role && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">ROLE :</span>
                    {pageData?.role?.desc && (
                      <span> {pageData?.role?.desc}</span>
                    )}
                  </p>
                  {pageData?.role?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.role.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Post processing block verification */}
              {pageData?.postProcessingBlockVerification && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">
                      Post processing block verification :
                    </span>
                    {pageData?.postProcessingBlockVerification?.desc && (
                      <span>
                        {" "}
                        {pageData?.postProcessingBlockVerification?.desc}
                      </span>
                    )}
                  </p>
                  {pageData?.postProcessingBlockVerification?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.postProcessingBlockVerification.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* QBC Verification */}
              {pageData?.qbcVerification && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">QBC Verification :</span>
                    {pageData?.qbcVerification?.desc && (
                      <span> {pageData?.qbcVerification?.desc}</span>
                    )}
                  </p>
                  {pageData?.qbcVerification?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.qbcVerification.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* CHALLENGES */}
              {pageData?.challenges && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">CHALLENGES :</span>
                    {pageData?.challenges?.desc && (
                      <span> {pageData?.challenges?.desc}</span>
                    )}
                  </p>
                  {pageData?.challenges?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.challenges.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* ACHIEVEMENT */}
              {pageData?.achievement && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">ACHIEVEMENT :</span>
                    {pageData?.achievement?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.achievement?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.achievement.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* deviceDriverDevelopment */}
              {pageData?.deviceDriverDevelopment && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">
                      Device Driver Development :
                    </span>
                    {pageData?.deviceDriverDevelopment?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.deviceDriverDevelopment?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.deviceDriverDevelopment.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Drone Solution Development */}
              {pageData?.droneSolutionDevelopment && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">
                      Drone Solution Development :
                    </span>
                    {pageData?.droneSolutionDevelopment?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.droneSolutionDevelopment?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.droneSolutionDevelopment.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Design */}
              {pageData?.design && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Design :</span>
                    {pageData?.design?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.design?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.design.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* signalIntegerity */}
              {pageData?.signalIntegerity && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Signal Integrity :</span>
                    {pageData?.signalIntegerity?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.signalIntegerity?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.signalIntegerity.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Key Features*/}
              {pageData?.keyFeatures && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Key features include :</span>
                    {pageData?.keyFeatures?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.keyFeatures?.pointers && (
                    <ol className="list-disc pl-7">
                      {pageData.keyFeatures.pointers.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Accomplishment*/}
              {pageData?.accomplishment &&
                pageData?.accomplishment?.verificationDecorScheme && (
                  <div className="font-montserrat">
                    <p>
                      <span className="font-bold">Accomplishments :</span>
                    </p>
                    <div className="font-montserrat">
                      <p className="ml-7 mt-4">
                        <span className="font-bold">
                          Verification of Decoder scheme :
                        </span>
                      </p>
                      {pageData?.accomplishment?.verificationDecorScheme && (
                        <ol className="list-disc pl-16">
                          {pageData.accomplishment?.verificationDecorScheme.map(
                            (ele: string, ind: number) => (
                              <li key={ind}>{ele}</li>
                            )
                          )}
                        </ol>
                      )}
                    </div>

                    <div className="font-montserrat">
                      <p className="ml-7 mt-4">
                        <span className="font-bold">
                          Verification of Encoder scheme :
                        </span>{" "}
                        In progress
                      </p>
                    </div>
                  </div>
                )}

              {/* Business Requirements :*/}
              {pageData?.businessRequirement && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Business Requirements :</span>
                    {pageData?.businessRequirement?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.businessRequirement && (
                    <ol className="list-disc pl-7">
                      {pageData.businessRequirement?.pointers?.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}
              {/* Solution Approach*/}
              {pageData?.solutionApproach && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Solution Approach :</span>
                    {pageData?.solutionApproach?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.solutionApproach && (
                    <ol className="list-disc pl-7">
                      {pageData.solutionApproach.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Technologies Used :*/}
              {pageData?.technologyUsed && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Technologies Used :</span>
                    {pageData?.technologyUsed?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.technologyUsed && (
                    <ol className="list-disc pl-7">
                      {pageData.technologyUsed?.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Benefits to Customer :*/}
              {pageData?.benefitsToCostumer && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Benefits to Customer :</span>
                    {pageData?.benefitsToCostumer?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.benefitsToCostumer && (
                    <ol className="list-disc pl-7">
                      {pageData.benefitsToCostumer?.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}

              {/* Sensor Composition:*/}
              {pageData?.sensorComposition && (
                <div className="font-montserrat">
                  <p>
                    <span className="font-bold">Sensor Composition:</span>
                    {pageData?.sensorComposition?.desc && (
                      <span> {pageData?.achievement?.desc}</span>
                    )}
                  </p>
                  {pageData?.sensorComposition && (
                    <ol className="list-disc pl-7">
                      {pageData.sensorComposition?.pointers?.map(
                        (ele: string, ind: number) => (
                          <li key={ind}>{ele}</li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CaseStudyDetailsPage;
