import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useState } from "react";
import storage from "../config/firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import emailjs from "@emailjs/browser";

type Props = { setIsModalOpen: Function };

const generateRandom = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(23).substring(2, 5);

const initialState: {
  name: string;
  email: string;
  phone: string;
  comment: string;
  file: Blob;
} = {
  name: "",
  email: "",
  phone: "",
  comment: "",
  file: null,
};
const applyInitialState = { status: false, message: "" };
const ApplyJobModal = ({ setIsModalOpen }: Props) => {
  const [inputData, setInputData] = useState(initialState);
  const [applyState, setApplyState] = useState(applyInitialState);
  const [applyFormLoading, setApplyFormLoading] = useState(false);

  const handleApplyState = (status: boolean, message: string) => {
    setApplyState({ ...applyState, status, message });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const inputSize = inputData.file?.size / 1024;
    if (inputSize > 500) {
      handleApplyState(false, "FileSize must be less than 500KB");
      return;
    }
    const templateParams = {
      name: inputData.name,
      email: inputData.email,
      phone: inputData.phone,
      comment: inputData.comment,
    };
    handleApplyState(false, "");
    setApplyFormLoading(true);

    const storageRef = ref(
      storage,
      // @ts-ignore
      `/blueberry/${generateRandom()}-${inputData.file.name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, inputData.file as Blob);
    try {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
        },
        (err) => {
          console.log(err);
          handleApplyState(false, "Something went wrong!");
        },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            // console.log(url);
            if (url) {
              try {
                const result = await emailjs.send(
                  process.env.REACT_APP_EMAILJS_SERVICE_ID,
                  process.env.REACT_APP_EMIALJS_APPLICATION_TEMPLATE,
                  { ...templateParams, attachment: url },
                  process.env.REACT_APP_EMAILJS_PUBLIC_KEY
                );
                handleApplyState(true, "Form has been submitted successfully!");
                setInputData(initialState);
              } catch (error) {
                handleApplyState(
                  false,
                  "Encountered an error while submitting!"
                );
              } finally {
                setApplyFormLoading(false);
              }
            } else {
              handleApplyState(false, "Error happened while uploading Image!");
              setApplyFormLoading(false);
            }
          });
        }
      );
    } catch (error) {
      handleApplyState(false, "Encountered an error while submitting!");
    } finally {
      setApplyFormLoading(false);
    }
  };

  return (
    <div className="w-[95%] md:w-[800px] lg:w-[1000px] h-[550px] md:h-[670px] max-h-[700px] bg-white fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] rouned-lg z-[100] p-4 px-6">
      <h1 className="text-[30px] md:text-[32px] lg:text-[35px] font-semibold font-heading text-center">
        Apply
      </h1>
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col gap-1 md:gap-5"
      >
        <div className="w-full flex flex-col gap-2">
          <label
            htmlFor="name"
            className="font-semibold text-[13px] md:text-[14px]"
          >
            Name<span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            required
            value={inputData.name}
            onChange={(e) =>
              setInputData({ ...inputData, name: e.target.value })
            }
            className="w-full h-[40px] p-2 border bg-[#fafafa]"
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <label
            htmlFor="email"
            className="font-semibold text-[13px]  md:text-[14px]"
          >
            Email<span className="text-red-600">*</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            required
            className="w-full h-[40px] p-2 border bg-[#fafafa]"
            value={inputData.email}
            onChange={(e) =>
              setInputData({ ...inputData, email: e.target.value })
            }
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <label
            htmlFor="phone"
            className="font-semibold text-[13px] md:text-[14px]"
          >
            Phone<span className="text-red-600">*</span>
          </label>
          <PhoneInput
            country={"in"}
            enableSearch={true}
            value={inputData.phone}
            onChange={(phone) => setInputData({ ...inputData, phone: phone })}
            inputClass={
              "border p-[10px] h-[48px] bg-[#fafafa] rounded numberInput"
            }
            placeholder="Contact Number"
            inputProps={{
              name: "phone",
              required: true,
            }}
          />
        </div>
        <div className="w-max flex flex-col gap-2">
          <label
            htmlFor="upload"
            className="font-semibold text-[13px]  md:text-[14px]"
          >
            Upload<span className="text-red-600">*</span>
          </label>
          <input
            type="file"
            required
            name="file"
            onChange={(e) =>
              setInputData({ ...inputData, file: e.target.files[0] })
            }
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <label
            htmlFor="comment"
            className="font-semibold text-[13px]  md:text-[14px]"
          >
            Comment<span className="text-red-600">*</span>
          </label>
          <textarea
            name="comment"
            id="comment"
            className="w-full h-[80px] md:h-[120px] p-2 border bg-[#fafafa]"
            value={inputData.comment}
            onChange={(e) =>
              setInputData({ ...inputData, comment: e.target.value })
            }
          ></textarea>
        </div>
        <div className="w-full flex justify-between items-center gap-2">
          <button
            type="submit"
            className="uppercase w-[150px] h-[35px] md:h-[42px] text-[14px] border-[2px] border-[#045e9ae0] text-[#045e9ae0] hover:bg-[#045e9ae0] hover:text-white font-semibold"
            disabled={applyFormLoading}
          >
            {applyFormLoading ? "Submitting" : "Submit"}
          </button>
          <button
            onClick={() => setIsModalOpen(false)}
            className="uppercase w-[150px] h-[35px] md:h-[42px] text-[14px] border-[2px] border-red-400 text-red-500 hover:bg-red-600 transition-all hover:text-white font-semibold"
          >
            Cancel
          </button>
        </div>
      </form>
      {applyState.message && (
        <p
          className={`mt-1 ${
            applyState.status ? "text-green-500" : "text-rose-500"
          } `}
        >
          {applyState.status
            ? `Hurray! ${applyState.message}`
            : `Oops! ${applyState.message}`}
        </p>
      )}
    </div>
  );
};

export default ApplyJobModal;
