const navigationItems = [
  { path: "/", text: "Home" },
  { path: "/about", text: "About" },
  {
    text: "Careers",
    subItems: [
      { path: "/careers/life-blueberry", text: "LIFE @ BLUEBERRY" },
      {
        path: "/careers/jobs",
        text: "Current Openings",
        subItems: [
          {
            path: "/careers/jobs/software-embedded",
            text: "software and embedded",
          },
          { path: "/careers/jobs/vlsi", text: "vlsi" },
        ],
      },
      { path: "/careers/testimonials", text: "Testimonials" },
      { path: "/careers/press-media", text: "Press & Media" },
    ],
  },
  {
    text: "Services",
    subItems: [
      { path: "/services/silicon-engineering", text: "Silicon Engineering" },
      { path: "/services/fpga-design", text: "FPGA Design" },
      { path: "/services/embedded-software", text: "Embedded Software" },
      { path: "/services/hardware-design", text: "Hardware Design" },
      { path: "/services/machine-learning", text: "Machine Learning" },
      { path: "/services/turnkey-solutions", text: "Turnkey Solutions" },
    ],
  },
  { path: "/casestudies", text: "Case Studies" },
  { path: "/contact", text: "Contact" },
];

const servicesLinks = [
  { path: "/services/silicon-engineering", text: "Silicon Engineering" },
  { path: "/services/fpga-design", text: "FPGA Design" },
  { path: "/services/embedded-software", text: "Embedded Software" },
  { path: "/services/hardware-design", text: "Hardware Design" },
  { path: "/services/machine-learning", text: "Machine Learning" },
  { path: "/services/turnkey-solutions", text: "Turnkey Solutions" },
];

const gridData = [
  { title: "Countries Served and Expanding", value: 7 },
  { title: "Projects Delivered Globally", value: 50 },
  { title: "Active Team Members Supporting", value: 120 },
  { title: "Years of Collective Team Experience", value: 100 },
];
const numbersArray = Array.from({ length: 18 }, (_, index) => 1 + index);

const cardArray = [
  {
    title: "Silicon Engineering",
    url: "/services/silicon-engineering",
    img: "./assets/Silicon-Engineering.png",
    desc: "We offer services in ASIC Front-end Microarchitecture Design & Implementation, ASIC/SoC Verification Signoff and RTL to GDS tasks, including the customized AMS chip design.",
  },
  {
    title: "FPGA Design",
    url: "/services/fpga-design",
    img: "./assets/FPGA-Design.png",
    desc: "We can take-up SOC-FPGA based design integration, Implementation & testing on target board including firmware development for built-in cpu cores",
  },

  {
    title: "Embedded Software",
    url: "/services/embedded-software",
    img: "./assets/Embedded-Software.png",
    desc: "We have extensively worked on Core Embedded software development from device driver layer to application layer. We have expertise to implement software on Multi-Core CPU.",
  },
  {
    title: "Hardware Design",
    url: "/services/hardware-design",
    img: "./assets/Hardware-design.png",
    desc: "Hardware design services cover Board design, Re-engineering, Developing Microcontroller based product prototyping and Data Acquisition System for IAC.",
  },
  {
    title: "Machine Learning",
    url: "/services/machine-learning",
    img: "./assets/Machine-Learning.png",
    desc: "Our offerings in Machine Learning include DNN Model Development & Deployment, Compiler Toolchain for AI-DNN processor & Develop/Enhance software solutions with AI-ML.",
  },
  {
    title: "Turnkey Solution",
    url: "/services/turnkey-solutions",
    img: "./assets/Turnkey-solution.png",
    desc: "We develop and offer complete turnkey solutions covering IC packaging, design and production of interconnect materials, front-end engineering test, wafer probing and final test",
  },
];
const quickLink = [
  { path: "/", text: "Home" },
  { path: "/about", text: "About" },
  { path: "/careers/jobs", text: "Careers" },
  { path: "/casestudies", text: "Case Studies" },
  { path: "/contact", text: "Contact" },
];
const informationalLink = [
  { path: "/careers/life-blueberry", text: "LIFE @ BLUEBERRY" },
  { path: "/careers/testimonials", text: "Testimonials" },
  { path: "/careers/press-media", text: "Press & Media" },
  { path: "/services/silicon-engineering", text: "Silicon Engineering" },
  { path: "/services/fpga-design", text: "FPGA Design" },
  { path: "/services/embedded-software", text: "Embedded Software" },
  { path: "/services/hardware-design", text: "Hardware Design" },
  { path: "/services/machine-learning", text: "Machine Learning" },
  { path: "/services/turnkey-solutions", text: "Turnkey Solutions" },
];
const caseStudies = [
  {
    url: "/casestudies/custom-layout-of-blocks-for-testchip",
    src: "/assets/casestudies/1.png",
    badge: "Silicon Engineering",
    head: "Silicon Engineering",
    title: "Custom Layout Of Blocks For TestChip",
    date: "July 28, 2022",
    time: "7:04 pm",
    pageData: {
      name: "Custom Layout Of Blocks For TestChip",
      "DOMAIN / CATEGORY": "Silicon Engineering / Semiconductor",
      tools: "Cadence, Mentor",
      projectDesc: {
        desc: "Custom Layout of blocks 12nm FinFET",
      },
      scope: {
        pointers: [
          "Custom Layout of design as per layout plan and instructions from SiliconLib",
          "Physical Verification (DRC, LVS) and LPE extraction if needed",
        ],
      },
      img: "/assets/services/custom-layout-of-blocks-for-testchip.jpeg",
      targetprocess: {
        desc: "12nm FinFET",
      },
      highlight: {
        pointers: ["Total 12 blocks", "Highspeed SERDES", "Low power"],
      },
      complexityOvr: {
        pointers: [
          "Tight schedule to meet MPW date",
          "PD kit integration into Tools",
        ],
      },
    },
  },
  {
    url: "/casestudies/multi-frequency-oscillator-design",
    src: "/assets/casestudies/2.png",
    badge: "Silicon Engineering",
    head: "Silicon Engineering",
    title: "Multi-Frequency Oscillator Design",
    date: "July 28, 2022",
    time: "6:58 pm",
    pageData: {
      name: "Multi-Frequency Oscillator Design",
      "DOMAIN / CATEGORY": "Silicon Engineering / Semiconductor",
      tools: "Cadence, Mentor",
      projectDesc: {
        desc: "Development of Multi-frequency oscillator from Specifications to GDS-II including custom Circuit design and Layout design and Characteristic verification",
      },
      scope: {
        pointers: [
          "Development of Multi-frequency oscillator",
          "Specifications to GDS-II",
          "Circuit design",
          "Layout design",
          "Characteristic verification",
        ],
      },
      img: "/assets/services/multi-frequency-oscillator-design.png",
      targetprocess: {
        desc: "TSMC CRN40G",
      },
      architecture: {
        pointers: [
          "Ensure start-up",
          "Stability of Loop",
          "Optimize Power and area",
          "Process mismatch to reduce Jitter",
          "Good PSRR for current generation block",
        ],
      },
    },
  },
  {
    url: "/casestudies/subsystem-verificationusb-host-interface-for-ssd-controller",
    src: "/assets/casestudies/3.png",
    badge: "Silicon Engineering",
    head: "Silicon Engineering",
    title: "Subsystem Verification USB Host Interface For SSD Controller",
    date: "July 28, 2022",
    time: "6:55 pm",
    pageData: {
      name: "Optimus HIM Sub-System",
      "DOMAIN / CATEGORY": "Silicon Engineering / Semiconductor",
      tools: "Git, Cadence xcelium, Simvision",
      application: "Flash memory and SSD devices.",
      projectDesc: {
        desc: "The Optimus HIM is a USB protocol host interface sub-system, used to interface UFM based SSD controller in the back-end. The UASP & BOT Protocol interfaces supported in USB sub-system.",
      },
      role: {
        desc: "Verification",
        pointers: [
          "Testplan development for HIM-SS",
          "Testcase development",
          "Functional coverage definition and development",
          "Debug of failures",
        ],
      },
      scope: {
        pointers: null,
      },
      challenges: {
        pointers: [
          "Understanding USB2.0/3.0/3.1/3.2 protocols from scratch and develop test plan for whole sub-system in a short time.",
          "Understanding control and data path in sub-system for E2E scenario development, debugs and to address queries if any from other audience or team members.",
        ],
      },
      img: "/assets/services/optimus-him-sub-system.png",
      targetprocess: null,
      architecture: null,
      achievement: {
        pointers: [
          "Good theoretical and working knowledge on USB2.0(FS/HS), USB3.2(Gen1x1, Gen1x2, Gen2x1 and Gen2x2).",
          "Testplan development for HIM-SS.",
          "E2E test cases development.",
          "Defining and developing functional coverage for USB device.",
        ],
      },
    },
  },
  {
    url: "/casestudies/adc-vip-for-datacalibration-processing",
    src: "/assets/casestudies/4.jpeg",
    badge: "Silicon Engineering",
    head: "Silicon Engineering",
    title: "ADC VIP For Data Calibration & Processing",
    date: "July 28, 2022",
    time: "6:48 pm",
    pageData: {
      name: "ADC Digital VIP",
      "DOMAIN / CATEGORY": "Silicon Engineering / Semiconductor",
      tools: "Cadence Xcelium",
      application:
        "Post processing of ADC output based on stored calibration coefficients for offset and gain correction.",
      projectDesc: {
        desc: "ADC digital VIP Verification",
        pointers: [
          "This chip is used in server as a result cache level is increased and external slaves are very large memories to store the data.",
          "CCN was used so that there is no bus constraints.",
          "CCN works on Round Robin node and has different slaves connected to different nodes",
          "Performance limitation => Speculation access from snoop control unit/cluster was coming out as Zero address for core-1, which was not accepted as per the design intent.",
        ],
      },
      role: {
        desc: " Verification of following is within scope for Blueberry.",
      },
      scope: null,
      postProcessingBlockVerification: {
        pointers: [
          "Involves co-sim verification where the Matlab generated calibration coefficient values are used as reference to check the DUT stored calibration values.",
        ],
      },
      challenges: {
        pointers: [
          "Integrating Matlab model to scoreboard",
          "Integrating QBC(.am) model to scoreboard",
        ],
      },
      qbcVerification: {
        pointers: [
          "Involves co-sim verification where the qbc (.also called as am )model generated coefficient values are used as reference to check the DUT stored values.",
        ],
      },
      img: "/assets/services/adc-digital-vip.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
    },
  },
  {
    url: "/casestudies/test-chips-for-mobile-processor-application",
    src: "/assets/casestudies/5.png",
    badge: "Silicon Engineering",
    head: "Silicon Engineering",
    title: "Multicore SoC TestChip For Mobile Application",
    date: "July 28, 2022",
    time: "6:39 pm",
    pageData: {
      name: "Test Chips For Mobile Processor Application",
      "DOMAIN / CATEGORY": "Silicon Engineering / Semiconductor",
      tools: "VCS",
      application: "Mobile Processor",
      projectDesc: {
        desc: "Multi-layer, Multi-core Soc",
        pointers: [
          "This test chips mainly targets for snapdragon processor.",
          "JTAG are nothing but the PAD’s which are sitting outside of the test chip. (basically on the boundary of the test chip)",
          "For every test chips, the initial verification will be the functionality check of the JTAG.",
          "The JTAG ID codes are different for different test chips.",
          "The test procedure which ran on silicon to get the yield are nothing but the “bit files” generated by the GLS team.",
          "Based on the silicon debug, any changes in the bit file (generated from GLS) prompts the changes in the DV test files(means test.sv file)",
        ],
      },
      highlight: {
        desc: "Reduced time2market by – proactivity meeting the milestone targeted for tape outs in order to achieve better yield of the test chip silicon.",
      },
      role: {
        desc: "Verification of following is within scope for Blueberry.",
        pointers: [
          "Connectivity checks between the blocks present in the test chip.",
          "Ensure JTAG (Joint test action group) interface are proper with respect to all the blocks present in the design.",
          "Create or update the verification suite based on the feature enhancements if needed.",
          "Make sure all the test procedures, which are targeted for silicon along with basic functionality are verified along with coverage closure.",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: {
        pointers: [
          "JTAG scan chain analysis/connectivity of all the blocks.",
          "Data integrity check w.r.t different blocks present in the test chip Soc",
          "Modify the test procedure based on the silicon debug to ensure better yield.",
        ],
      },
      qbcVerification: null,
      img: "/assets/services/test-chips-for-mobile-processor-application.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
    },
  },
  {
    url: "/casestudies/post-silicon-validationof-navigation-chip",
    src: "/assets/casestudies/6.png",
    badge: "Hardware Design",
    head: "Hardware Design",
    title: "Post Silicon Validation of Navigation Chip",
    date: "July 28, 2022",
    time: "6:35 pm",
    pageData: {
      name: "Post Silicon Validation Of SoC",
      "DOMAIN / CATEGORY": "Hardware Design / Automotive",
      tools: "Eclipse, GCC, Python IDE",
      language: "C, Python",
      application: null,
      scriptingLanguage: null,
      projectDesc: {
        desc: "GNSS based, dual-core (Esi-RISC)",
        pointers: [
          "It was exciting to work on the post-silicon phase of the DUT on which pre-silicon verification was performed.",
          "The setup had a huge PCB board on which 2 FPGA’s (A & B) and base board were sitting.",
          "The FPGA’s had master/slave logic for all the peripheral controllers, I/O Muxes available inside the DUT. Python was the only way to talk to these FPGA’s.",
          "Most of the verification phase testcases were ported as they are with additional logic added wherever is needed.",
          "The difficult part was, validating peripheral controllers with limited visibility to internal signals.",
          "Observing the signals (data + control) on the oscilloscope to make sure what is being driven from/to DUT is reflected on the IO pins with minimum loss.",
          "Identifying possible root cause of any issue which could be from defective DUT and/or Package and/or FPGA and/or improper Connections and/or incorrect configurations and/or compatibility issues with FPGA’s master/slave with DUT’s slave/master etc.",
        ],
      },
      highlight: null,
      role: {
        desc: " Validation (post-silicon validation)",
        pointers: [
          "APB0/1 and AHB level peripheral’s validation",
          "Synchronization between base board (SoC) and FPGA’s (A & B)",
          "Validating frequencies and data paths of peripheral’s using oscilloscope.",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: {
        pointers: [
          "Limited visibility to internal signals (wires) of DUT",
          "Synchronization between python (FPGA) and C (SoC)",
          "Identifying and categorizing DUT’s genuine issues and Package/connectivity issues",
          "Power supply and current consumption monitoring",
        ],
      },
      qbcVerification: null,
      img: "/assets/services/post-silicon-vaildation-of-soc.jpeg",
      targetprocess: null,
      architecture: null,
      achievement: null,
      deviceDriverDevelopment: null,
      droneSolutionDevelopment: null,
    },
  },
  {
    url: "/casestudies/ddr5-lpddr5interface-verification",
    src: "/assets/casestudies/7.png",
    badge: "Silicon Engineering",
    head: "Silicon Engineering",
    title: "DDR5/LPDDR5 Interface Verification",
    date: "July 28, 2022",
    time: "6:22 pm",
    pageData: {
      name: "DDR5/LPDDR5X Multiply IP",
      "DOMAIN / CATEGORY": "Silicon Engineering / Semiconductor",
      tools: "VCS, Perl",
      application: "Applicable To All DRAM’s",
      projectDesc: {
        desc: "The combophy interface is an interface protocol required to transfer command and data across the DFI and between MC & PHY. Combophy applies to LPDDR5 & DDR5",
        pointers: [
          "This test chips mainly targets for snapdragon processor.",
          "JTAG are nothing but the PAD’s which are sitting outside of the test chip. (basically on the boundary of the test chip)",
          "For every test chips, the initial verification will be the functionality check of the JTAG.",
          "The JTAG ID codes are different for different test chips.",
          "The test procedure which ran on silicon to get the yield are nothing but the “bit files” generated by the GLS team.",
          "Based on the silicon debug, any changes in the bit file (generated from GLS) prompts the changes in the DV test files(means test.sv file)",
        ],
      },
      highlight: null,
      role: {
        desc: "Verification of following is within scope for Blueberry",
        pointers: [
          "Enable class A and class B coverage for both d5and lp5",
          "Enable HW EMUL builds for ComboPhy for both d5 and lp5",
          "GLS simulation for d5 and lp5",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: {
        pointers: [
          "For GLS added bit blasted signals, modified hierarchy & run regression and debugging the failing tests.",
        ],
      },
      qbcVerification: null,
      img: "/assets/services/dd5-lpddr5x.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
    },
  },
  // {
  //   url: "/casestudies/multicore-soc-for-high-performanceflash-memory-controller-verification/",
  //   src: "/assets/casestudies/8.png",
  //   badge: "Silicon Engineering",
  //   head: "Silicon Engineering",
  //   title:
  //     "Multicore SoC For High Performance Flash Memory Controller Verification",
  //   date: "July 28, 2022",
  //   time: "6:17 pm",
  // },
  // {
  //   url: "/casestudies/subsystem-verification-ofimage-compression-block/",
  //   src: "/assets/casestudies/9.png",
  //   badge: "Silicon Engineering",
  //   head: "Silicon Engineering",
  //   title: "Subsystem Verification of Image Compression Block",
  //   date: "July 28, 2022",
  //   time: "6:09 pm",
  // },
  {
    url: "/casestudies/soc-for-5g-application",
    src: "/assets/casestudies/10.png",
    badge: "Hardware Design",
    head: "Hardware Design",
    title: "SoC For 5G Application",
    date: "July 28, 2022",
    time: "6:04 pm",
    pageData: {
      name: "SoC For Video Application",
      "DOMAIN / CATEGORY": "Hardware Design / Semiconductor",
      tools: "VCS, Questa Sim",
      language: "C, Python",
      application: "RF IF SoC for 5G",
      scriptingLanguage: null,
      projectDesc: {
        desc: "Multi-master, Multi-core (ARM A53)",
        pointers: [
          "This chip is used in server as a result cache level is increased and external slaves are very large memories to store the data.",
          "CCN was used so that there is no bus constraints.",
          "CCN works on Round Robin node and has different slaves connected to different nodes",
          "Performance limitation => Speculation access from snoop control unit/cluster was coming out as Zero address for core-1, which was not accepted as per the design intent.",
        ],
      },
      highlight: null,
      role: {
        desc: "Verification of AM controller block with",
        pointers: [
          "Read/write test",
          "Multiple host access test",
          "FIFO full test",
          "Timeout check",
          "Maximum power test",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: {
        pointers: [
          "SBFC Parser data padding",
          "C2C to LVDS data transfer without loss.",
        ],
      },
      qbcVerification: null,
      img: "/assets/services/soc_for_5g_app.png",
      targetprocess: null,
      architecture: null,
      achievement: { pointers: ["Coverage improvement from 81% to 97%."] },
      deviceDriverDevelopment: null,
      droneSolutionDevelopment: null,
    },
  },
  {
    url: "/casestudies/signal-integrity-for-high-speed-io-of-lpddr4",
    src: "/assets/casestudies/13.png",
    badge: "Hardware Design",
    head: "Hardware Design",
    title: "Signal Integrity For High Speed IO Of LPDDR4",
    date: "July 28, 2022",
    time: "5:39 pm",
    pageData: {
      name: "Signal Integrity of LPDDR4 IO",
      "DOMAIN / CATEGORY": "Hardware Design / Semiconductor",
      tools: "HyperLynx, Ansys, SnP Expert and H-Spice.",
      application: "Evaluation Board",
      projectDesc: {
        desc: "DSPG-ASIC – 7nm Rhea LPDDR4 & 4x, IO validation",
      },
      highlight: null,
      role: {
        desc: "Verification of following is within scope for Blueberry",
      },
      design: {
        pointers: ["LPDDR4 and 4x", "TM NAND IO Acceptance."],
      },
      signalIntegerity: {
        pointers: [
          "Simulation of LPDDR4 at 4266 Mbps in System Level simulation",
          "Simulation of LPDDR4x at 4266 Mbps in System Level simulation.",
          "Simulation of NAND IO at 2400 Mbps in System Level simulation",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: {
        pointers: [
          "Simulation of LPDDR4,4x and NAND IO at system level with top speed.",
          "Validation of NAND IO net-list received from ASIC IO Team.",
          "Connecting the signals on PCB with shorter lengths by optimizing placements.",
        ],
      },
      qbcVerification: null,
      img: "/assets/services/signal_integrity.png",
      targetprocess: null,
      architecture: null,
      achievement: {
        pointers: [
          "Signal Integrity for LPDDR4 and NAND IO is achieved with good margins at top speed in system level simulations.",
        ],
      },
    },
  },
  {
    url: "/casestudies/verification-of-integrated-flight-controlelectronics-developing-reusable-testbench-components",
    src: "/assets/casestudies/14.jpeg",
    badge: "FPGA Design Verification",
    head: "Fpga Design",
    title:
      "Verification Of Integrated Flight Control Electronics Developing Reusable Testbench Components",
    date: "July 28, 2022",
    time: "5:34 pm",
    pageData: {
      name: "Integrated Flight Control Electronics (IFCE)",
      "DOMAIN / CATEGORY": "FPGA Design Verification / Avionics",
      tools: "Questasim, DOORS",
      application: "Flight Control Module",
      projectDesc: {
        desc: "Actuator Control Electronics(ACE) Direct Mode",
        pointers: [
          "The ACE architecture contains two distinct parts; One part is referred to as the Normal Mode path and the other part as the Direct Mode path.",
          "The ACE is partitioned this way to ensure functional and design independence between the Direct Mode and Normal Mode paths.",
          "The Direct Mode path receives only those inputs that are needed to support the computation of the Direct Mode Control laws.",
          "The Direct Mode Path within the ACE is used as a backup control system for the IFCE.",
          "Direct Mode can be engaged either by the pilot using manual switches in the cockpit or if the ACE losses IMB communications with all FCM’s or if commanded by the FCMs.",
          "The Direct Mode Path contains a set of aircraft CLAWs that use rate and cockpit sensor information to generate actuator commands to the REUs.",
        ],
      },
      highlight: null,
      role: {
        desc: "ACE Direct Mode Path Module Verification",
        pointers: [
          "Actuator Data Bus (ADB) Tx Verification",
          "FCDIS Fault Detection and Coherency between FCDIS Local Data and Crosslane CLDL data.",
          "Crosslane Data Link(CLDL) Storage and Transmission Verification .",
          "Elevator and Rudder Control Verification",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: {
        pointers: ["Test Bench Optimization with respect to simulation time."],
      },
      qbcVerification: null,
      img: "/assets/services/2022/07/ifce.png",
      targetprocess: null,
      architecture: null,
      achievement: {
        pointers: [
          "Created re-usable common task and testbench module for NVM Fault checks.",
        ],
      },
    },
  },
  {
    url: "/casestudies/compiler-development-for-speech-processing-soc",
    src: "/assets/casestudies/15.png",
    badge: "Machine Learning",
    head: "Machine Learning",
    title: "Compiler Development For Speech Processing SoC",
    date: "July 28, 2022",
    time: "5:27 pm",
    pageData: {
      name: "Compiler Development for AI Chip",
      "DOMAIN / CATEGORY":
        "Machine Learning & Turnkey – Software Solutions / Consumer Electronic",
      tools: null,
      application: null,
      scriptingLanguage: null,
      projectDesc: {
        desc: "Compiler for speech processing on the SoC Board using DNN",
        pointers: [
          "IP Verification of VESA DSC (Display Stream Compression).",
          "DSC is an industry wide compression standard for vedio interfaces that features low latency and visually lossless performance, DSC is currently integrated into standards used for embedded display interfaces within the mobile systems.",
          "Encoding and decoding schemes are deployed in DSC v1.2a to provide backward compatibility with DSC v1.1. The capabilities in DSC v1.2a allows the standard to be utilized not only for mobile displays, but also for emerging high definition TVs.",
        ],
      },
      keyFeatures: {
        pointers: [
          "Native 4:2:2 and 4:2:0 coding.",
          "Upto 16 bits per color.",
          "High dynamic range (HDR).",
        ],
      },
      accomplishment: {
        verificationDecorScheme: [
          "Single frame and single PPS (Picture Parameter Set) programming using APB 3 host interface. Scope of Operation modes Ycbcr, RGB, Native 422,Native 420 and Simple 420 are covered.",
          "Multi frame and single PPS programming using APB 3 host interface. Scope of Operation modes Ycbcr, RGB, Native 422,Native 420 and Simple 420 are covered.",
          "Multi frame and multiple PPS programming using APB 3 as host interface. Scope of Operation modes Ycbcr, RGB, Native 422,Native 420 and Simple 420 are covered.",
          "Flush and INIT based verification.",
          "CTG (Confirmance Test Guidliance) testing as per industry standards of 1k,4k,8k,10k and colorbar resolution images",
          "Latency analysis and verification in multi frame scenario is the major challenging role to meet the refresh rate as per the vedio interface standards.",
          "Implementation of RAL based register testing to verify the values of internal control and status registers of decoder design.",
        ],
        verificationOfEncoderScheme: {
          desc: "In progress",
        },
      },
      solutionApproach: [
        "Layer 1: ONNX : We built it around the ONNX-a framework for extracting the DNN model",
        "Layer 2: MLIR: We used MLIR for DAG to IR conversion of a DNN. The target dialect is the LLVM dialect.",
        "Layer 3: LLVM: We import the MLIR output for further HW specific transformation that is compatible with the ARM Cortex M4 processor.",
      ],
      highlight: null,
      role: null,
      scope: null,
      postProcessingBlockVerification: null,
      challenges: null,
      qbcVerification: null,
      img: "/assets/services/al_chip_slide.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
      deviceDriverDevelopment: null,
      droneSolutionDevelopment: null,
    },
  },
  {
    url: "/casestudies/nlp-processing-an-automatedresume-processing-application",
    src: "/assets/casestudies/16.png",
    badge: "Machine Learning",
    head: "Machine Learning",
    title: "NLP Processing, An Automated Resume Processing Application",
    date: "July 28, 2022",
    time: "5:26 pm",
    pageData: {
      name: "NLP Processing Using AI",
      "DOMAIN / CATEGORY": "Machine Learning",
      tools: null,
      application: null,
      scriptingLanguage: null,
      projectDesc: null,
      keyFeatures: null,
      accomplishment: null,
      businessRequirement: {
        desc: "Automated Resume Screening software.",
        pointers: [
          "Singapore based staff augmentation/manpower sourcing company with clients to multiple domains.",
          "The customer wanted a resume parsing and conversion to HR-JSON format. The matching of the resumes to a selected JD with ranking of the results.",
        ],
      },
      solutionApproach: [
        "The input to the system can be a html, word or a pdf document that is converted to a normalized text file format.",
        "Multiple NLP processing schemes are applied to extract the relevant sections of the JD and Resumes.",
        "A scoring engine then ranks the match or the mandatory and good to have skills.",
        "The results of the search & match are displayed of an intuitive dashboard.",
        "Search refinements can be carried out in the system with unspecified requirements , like, joining date, CTC, travel rediness, etc.",
      ],
      technologyUsed: [
        "AI/ML algos including ANN, DNN, and simple regex.",
        "Flexible and customizable NLP pipeline.",
        "Includes a smart text generator.",
        "Uses LAMP stack.",
        "The GUI is a HTML/JS script.",
        "Backend: MongoDB, Flask and Restful API.",
        "Uses domain specific taxonomies.",
      ],
      benefitsToCostumer: [
        "Quick turn-around to client request.",
        "More time spent on validating JD and candidate claims.",
        "Match the resume database to multiple JDs.",
        "and shortlist the same candidate to the best match.",
        "Submitting the resumes to client in a standardised format.",
        "System has facilities to allow the recruiter to add additional comments / observations.",
        "made by the recruiter or the clients to improve future matches of the candidate.",
      ],
      highlight: null,
      role: null,
      scope: null,
      postProcessingBlockVerification: null,
      challenges: null,
      qbcVerification: null,
      img: "/assets/services/NLP-Language-Processing.jpg",
      targetprocess: null,
      architecture: null,
      achievement: null,
      deviceDriverDevelopment: null,
      droneSolutionDevelopment: null,
    },
  },
  {
    url: "/casestudies/smart-camera-for-face-recognitionaccess-control-and-attendance-monitoring",
    src: "/assets/casestudies/18.png",
    badge: "Embedded SW",
    head: "Embedded Software",
    title:
      "Smart Camera For Face Recognition, Access Control and Attendance Monitoring",
    date: "July 28, 2022",
    time: "5:03 pm",
    pageData: {
      name: "Embedded Systems",
      "DOMAIN / CATEGORY": "Embedded SW / Security & Surveillance",
      tools: null,
      application: null,
      scriptingLanguage: null,
      projectDesc: null,
      highlight: null,
      role: null,
      scope: null,
      postProcessingBlockVerification: null,
      challenges: null,
      qbcVerification: null,
      img: "/assets/services/Drone-Application.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
      deviceDriverDevelopment: {
        pointers: [
          "Single board AI/ML processor based on RISC-V.",
          "Linux on RISC-V processor.",
          "Device types: GPIO, SPI, I2C",
          "C/C++ code",
        ],
      },
      droneSolutionDevelopment: {
        pointers: [
          "Drone controller for Quad and Hexa copters.",
          "Drone to base station communication",
          "Drone fleet management",
        ],
      },
    },
  },
  {
    url: "/casestudies/complex-multi-fpga-fail-safedesign-verification",
    src: "/assets/services/FPGA-300x185.png",
    badge: "FPGA Design Verification",
    head: "Fpga Design",
    title: "Complex Multi-FPGA Fail-Safe Design Verification",
    date: "July 28, 2022",
    time: "4:23 pm",
    pageData: {
      name: "777x FPGA Verification",
      "DOMAIN / CATEGORY": "FPGA Design Verification / Avionics",
      tools: "Questasim",
      application: null,
      scriptingLanguage: "Questasim",
      projectDesc: {
        desc: "Multi- FPGA Verification",
        pointers: [
          "It’s a program from Boeing.",
          "The 777x aircraft is the 1st and only aircraft from Boeing where the wings can be folded (~30%) in order to have a safe taxi.",
          "Sometimes the requirement which have mentioned can’t be actually feasible in simulation, and needs to be taken care in Hardware.",
          "These types of requirements are tedious to figure out. (which involves multiple discussion with the customer.",
        ],
      },
      highlight:
        "Reduced time2market by – proactivity meeting the milestone on time for the first flight of 777x program.",
      role: {
        desc: "Verification of following is within scope for Blueberry.",
        pointers: [
          "Verify the functionality w.r.t different FPGA.",
          "Development of the verification environment as per the avionics standard coding guideline .",
          "All implementation are inline with agile review process to make sure there is no process violation.",
          "Take the complete ownership of the verification issues that have been raised, do follow up with the designers to have a close on the issues.",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: {
        pointers: [
          "Understand the requirement and implement the test procedure.",
          "Handshaking mechanism between different “command” and “monitor” lane of different FPGA’s",
          "Follow the Agile process to close the review of all the artifacts.",
        ],
      },
      qbcVerification: null,
      img: "/assets/services/FPGA.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
    },
  },
  {
    url: "/casestudies/compiler-development-for-speech-processing-soc",
    src: "/assets/services/al_chip.png",
    badge: "Turnkey Solutions",
    head: "Turnkey Solutions",
    title: "Compiler Development For Speech Processing SoC",
    date: "June 6, 2022",
    time: "11:17 am",
    pageData: {
      name: "Compiler Development for AI Chip",
      "DOMAIN / CATEGORY":
        "Machine Learning & Turnkey – Software Solutions / Consumer Electronic",
      tools: null,
      application: null,
      scriptingLanguage: null,
      projectDesc: {
        desc: "Compiler for speech processing on the SoC Board using DNN <br/> <br/> IP Verification of VESA DSC (Display Stream Compression). <br/> <br/> DSC is an industry wide compression standard for vedio interfaces that features low latency and visually lossless performance, DSC is currently integrated into standards used for embedded display interfaces within the mobile systems. <br/> <br/> Encoding and decoding schemes are deployed in DSC v1.2a to provide backward compatibility with DSC v1.1. The capabilities in DSC v1.2a allows the standard to be utilized not only for mobile displays, but also for emerging high definition TVs.",
      },
      keyFeatures: {
        pointers: [
          "Native 4:2:2 and 4:2:0 coding.",
          "Upto 16 bits per color.",
          "High dynamic range (HDR).",
        ],
      },
      accomplishment: {
        verificationDecorScheme: [
          "Single frame and single PPS (Picture Parameter Set) programming using APB 3 host interface. Scope of Operation modes Ycbcr, RGB, Native 422,Native 420 and Simple 420 are covered.",
          "Multi frame and single PPS programming using APB 3 host interface. Scope of Operation modes Ycbcr, RGB, Native 422,Native 420 and Simple 420 are covered.",
          "Multi frame and multiple PPS programming using APB 3 as host interface. Scope of Operation modes Ycbcr, RGB, Native 422,Native 420 and Simple 420 are covered.",
          "Flush and INIT based verification.",
          "CTG (Confirmance Test Guidliance) testing as per industry standards of 1k,4k,8k,10k and colorbar resolution images",
          "Latency analysis and verification in multi frame scenario is the major challenging role to meet the refresh rate as per the vedio interface standards.",
          "Implementation of RAL based register testing to verify the values of internal control and status registers of decoder design.",
        ],
      },
      businessRequirement: null,
      solutionApproach: [
        "Layer 1: ONNX : We built it around the ONNX-a framework for extracting the DNN model",
        "Layer 2: MLIR: We used MLIR for DAG to IR conversion of a DNN. The target dialect is the LLVM dialect.",
        "Layer 3: LLVM: We import the MLIR output for further HW specific transformation that is compatible with the ARM Cortex M4 processor.",
      ],
      technologyUsed: null,
      benefitsToCostumer: null,
      highlight: null,
      role: null,
      scope: null,
      postProcessingBlockVerification: null,
      challenges: null,
      qbcVerification: null,
      img: "/assets/services/al_chip_slide.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
      deviceDriverDevelopment: null,
      droneSolutionDevelopment: null,
    },
  },
  {
    url: "/casestudies/3d-localized-augmented-gps-based-solutionfor-locating-worker-in-the-field",
    src: "/assets/services/W3D-Sensor.jpg",
    badge: "Turnkey Solutions",
    head: "Turnkey Solutions",
    title:
      "3D Localized Augmented GPS Based Solution For Locating Worker In The Field",
    date: "June 6, 2022",
    time: "11:17 am",
    pageData: {
      name: "3D Localization – Augmented GPS Solution",
      "DOMAIN / CATEGORY": "Turnkey- IoT Solution / Industrial Automation",
      tools: null,
      application: null,
      scriptingLanguage: null,
      technolgy: "Developed using Home Grown IoT Stack",
      projectDesc: {
        desc: "3D Localization – Augmented GPS Solution",
      },
      keyFeatures: null,
      accomplishment: null,
      businessRequirement: null,
      solutionApproach: null,
      technologyUsed: null,
      benefitsToCostumer: null,
      highlight: null,
      role: {
        desc: "Develop and demonstrate solution to locate object in field",
        pointers: [
          "W3D sensor provides for sensing of locations, activity, temperature and humidity in a compact wearable sensor cluster. When used this MMM IoT Platform, it provides for: Location measurement: important in many applications. and Activity monitoring that is gaining popularity for both personal health and other commercial applications.",
          "In-building location sensing in 3 dimensions, i.e., Lat + Long + Altitude is enabled in our solution.",
        ],
      },
      sensorComposition: {
        pointers: [
          "Temperature Sensor for ambient temperature sensing",
          "9 Axis Motion Sensor from TI",
          "Pressure sensor for altitude from Robert Bosch",
          "Humidity sensor from TI",
          "Locating object in a field in 3 dimension",
          "Portable Sensor",
          "Software Development using Home Grown IoT stack",
        ],
      },
      scope: null,
      postProcessingBlockVerification: null,
      challenges: null,
      qbcVerification: null,
      img: "/assets/services/2022/07/al_chip_slide.png",
      targetprocess: null,
      architecture: null,
      achievement: null,
      deviceDriverDevelopment: null,
      droneSolutionDevelopment: null,
    },
  },
];

const servicesPageData: Record<string, { path: string; title: string }> = {
  "silicon-engineering": {
    path: "/silicon-engineering",
    title: "Silicon Engineering",
  },
  "fpga-design": {
    path: "/fpga-design",
    title: "Fpga Design",
  },
  "embedded-software": {
    path: "/embedded-software",
    title: "Embedded Software",
  },
  "hardware-design": {
    path: "/hardware-design",
    title: "Hardware Design",
  },
  "machine-learning": {
    path: "/machine-learning",
    title: "Machine Learning",
  },
  "turnkey-solutions": {
    path: "/turnkey-solutions",
    title: "Turnkey Solutions",
  },
};

const testimonialsData = [
  {
    message:
      "I can say I feel empowered with kind of experiences I have had with the company. I am proud to be a part of the blueberry semiconductors family.",
    img: "/assets/testimonials/Dushayanth.jpeg",
    name: "DUSHYANT",
    des: "Software Engineer",
  },
  {
    message:
      "A very good company to start the career as freshers. Colleagues are approachable, supportive and helpful. Lots of opportunities for our professional and personal growth.",
    img: "/assets/testimonials/Shradha.jpeg",
    name: "SHRADDHA",
    des: "Verification Engineer",
  },
  {
    message:
      "I feel very proud to be one of the oldest employee of Blueberry semiconductors pvt ltd. I can proudly say this is my first and best move, and I am happy to continue my career.",
    img: "/assets/testimonials/Rakshith.jpeg",
    name: "RAKSHIT",
    des: "Software Engineer",
  },
  {
    message:
      "I started my career at Blueberry Semiconductors as DV trainee and met with lots of great personalities. Captured lots of knowledge, skills and still acquiring lots of new ideas everyday. Looking forward to meet many more personalities and learn new things and build my career in great way.",
    img: "/assets/testimonials/nayana.jpeg",
    name: "NAYANA CP",
    des: "Verification Engineer",
  },
  {
    message:
      "Blueberry Semiconductor is a Top semiconductor and Embedded service company , grown to 100 Member company within span of 3 Years. Holding a strong client portfolios. Company has extended strong services to clients and growing with pace.",
    img: "/assets/testimonials/Gopinath.jpeg",
    name: "GOPI A",
    des: "TA Manager",
  },
  {
    message:
      "Blueberry semiconductor is a very good platform for learning. I'm very proud to be work here with Supporting team. Personally I would like to thank Pooja, Naveen sir, Nitin sir and Gopi sir to giving me chance to work here. Very friendly environment, which makes to work happily.",
    img: "/assets/testimonials/Sowmya.jpeg",
    name: "SOWMYA BHATT",
    des: "HR Recruiter",
  },
  {
    message:
      "Great place to work, supportive colleagues and encouraging management. What else would one need to work in a healthy workplace",
    img: "/assets/testimonials/Sreeharsha.jpeg",
    name: "SREEHARSHA",
    des: "Verification Engineer",
  },
  {
    message:
      "I started out as a Trainee at Blueberry semiconductors after my graduation. Upon completing the training, I found myself in my first full time opportunity working with an amazing company, doing something I enjoy and progressing my career professionally and personally.",
    img: "/assets/testimonials/Krishna.jpeg",
    name: "KRISHNA MURTHY",
    des: "Verification Engineer",
  },
  {
    message:
      "I have been working here since 2019. I joined as Embedded trainee.The company supported in enhancing my skills. The company atmosphere motivates to do our level best. The Blueberry management is supportive in all aspects.",
    img: "/assets/testimonials/aruna-K.jpeg",
    name: "ARUNA K",
    des: "Software Engineer",
  },
  {
    message:
      "I joined company during my mtech internship in embedded domain. Blueberry helped me to switch from embedded to VLSI domain, where I got exposed to lot of opportunities. Was provided an opportunity to work on ASIC domain which I was interested to work so as to widen my knowledge.",
    img: "/assets/testimonials/PoojaM.jpeg",
    name: "M POOJA",
    des: "Verification Lead",
  },
  {
    message:
      "It's the right platform to kickstart your career in the VLSI domain. The company ecosystem keeps us learning continuously with good challenges. Blueberry always will recognize employee performance and offers rewards to move ahead.",
    img: "/assets/testimonials/Dileep.jpeg",
    name: "DILEEP MUKKERA",
    des: "Verification Engineer",
  },
  {
    message:
      "I feel very proud to be here in Blueberry Semiconductor as Sr. Verification Engineer. My career has skied with the growth of the company. The extraordinary Knowledge Transfer across the different streams of the project has benefited lot of employee. I am really grateful to work in a place which makes us grow to greater heights.",
    img: "/assets/testimonials/pavan.jpeg",
    name: "PAVAN M",
    des: "Sr Verification Engineer",
  },
  {
    message:
      "Blueberry Semiconductor always gave a friendly environment with co-workers and was more supportive. It is a fun filled company with many events for employees at occasions but at the same time they work for getting the job done right, on time and correctly.",
    img: "/assets/testimonials/nandhini.jpeg",
    name: "NANDHINI R",
    des: "Verification Engineer",
  },
  {
    message:
      "Blueberry Semiconductors provides us a conducive and employee friendly work environment, which helps the employee to give quality work to the clients. If you want to work in good environment, you can join any place, but if you want to work in a great environment - I say 'this is the place.'",
    img: "/assets/testimonials/akashPatil.jpeg",
    name: "AAKASH PATIL",
    des: "Sr Signal Integrity Engineer",
  },
  {
    message:
      "The most important thing that I would appreciate is the learning that comes with those opportunities that fueled my learning appetite. I've always been glad to be with Blueberry Semiconductor Pvt. Ltd because is the right place to learn and to execute my thoughts and ideas on any domain as I wish.",
    img: "/assets/testimonials/Suraj.jpeg",
    name: "SURAJ",
    des: "Verification Engineer",
  },
  {
    message:
      "The organization has a good well-experienced management team. They were supportive during the pandemic.",
    img: "/assets/testimonials/Shashi.jpeg",
    name: "SHASHI",
    des: "Sr Verification Engineer",
  },
  {
    message:
      "A positive working environment and a very supportive friendly environment. Everyday filled with new learning opportunities.",
    img: "/assets/testimonials/Vikash.jpeg",
    name: "VIKASH",
    des: "Verification Engineer",
  },
  {
    message:
      "Here all are friendly nature and good enviromment, lots of opportunity, flexible work. Specially, I will thank to Nitin sir, Naveen sir ,Pooja joshi mam, Gopi sir.",
    img: "/assets/testimonials/Supriya.jpeg",
    name: "SUPRIYA",
    des: "HR Recruiter",
  },
];

const pressMediaImages = [
  "/assets/press_media/10_0008-scaled.jpeg",
  "/assets/press_media/9_0001-scaled.jpeg",
  "/assets/press_media/10_0001-2048x1448.jpeg",
  "/assets/press_media/2_Most-Promising-Startup.jpeg",
  "/assets/press_media/1_Company-of-the-Month.jpeg",
  "/assets/press_media/14_0005-scaled.jpeg",
  "/assets/press_media/13_0011-scaled.jpeg",
  "/assets/press_media/12_0010-scaled.jpeg",
  "/assets/press_media/11_0009-scaled.jpeg",
];

const jobsTitles = [
  {
    title: "Analog Layout Engineer (All Levels)",
    url: "analog-layout-engineer-all-levels",
  },
  {
    title: "Analog Design Engineer (All Levels)",
    url: "analog-design-engineer-all-levels",
  },
  {
    title: "Physical Design Engineer (All Levels)",
    url: "physical-design-engineer-all-levels",
  },
  { title: "DFT Engineer (All Levels)", url: "dft-engineer-all-levels" },
  {
    title: "Design Verification Engineer (All Levels)",
    url: "design-verification-engineer-all-levels",
  },
  {
    title: "RTL FPGA Engineer (All Levels)",
    url: "rtl-fpga-engineer-all-levels",
  },
  {
    title: "RTL ASIC Engineer (All Levels)",
    url: "rtl-asic-engineer-all-levels",
  },
];

const jobData: Record<
  string,
  {
    location: string;
    experience: string;
    "Preferred Education": string[];
    "Area of Expertise": string[];
  }
> = {
  "analog-layout-engineer-all-levels": {
    location: "Bangalore",
    experience: " 1-15 Years",
    "Preferred Education": ["B.Tech/M.Tech"],
    "Area of Expertise": [
      "Hands-on experience in developing Analog Layout /IO layout design",
      "Good exposure on FinFet layouts in lower nodes",
      "Expertise in using the best and latest features of Cadence and Calibre DRC/LVS",
      "Good exposure to ESD, LUP, antenna layout challenges, and analyzing/fixing EMIR issues",
      "Capable of working independently and with the team and getting work done",
      "The ability to work & communicate effectively with global engineering teams",
    ],
  },
  "analog-design-engineer-all-levels": {
    location: "Bangalore",
    experience: " 1-15 Years",
    "Preferred Education": ["B.Tech/M.Tech"],
    "Area of Expertise": [
      "Deep understanding of Circuit design/ physical design of Analog Designs on advanced process technologies",
      "Expertise in analogy design of blocks like Analog to Digital converters, Amplifiers, Switch-cap circuits, Voltage, and Current Reference Generation circuits, GPIO, Differential Tx / Rx, oscillators, ESD, etc",
      "Expertise in Reliability aware design and familiarity with Aging and RV tools. > Ability to design floor plan and routing in Layout",
      "Good grasp of Industry-standard tools such as Cadence design Environment (ADEXL or Virtuoso) Knowledge of DRC, LVS, and post-layout extraction tools, etc",
      "Familiarity with RTL behavioral coding and simulations, timing extractions of custom blocks",
      "Strong communicator and proven leadership experience, self-driven, proactive nature to own and deliver high-quality end to end Analog designs",
      "Fast learner, good problem-solving skills, multitasking ability, and attention to quality and detail.",
      "Expertise in mixed-signal designs and data-converters (Discrete / Continuous-time Sigma-Delta) is a major plus",
    ],
  },
  "physical-design-engineer-all-levels": {
    location: "Bangalore",
    experience: " 1-15 Years",
    "Preferred Education": ["B.Tech/M.Tech"],
    "Area of Expertise": [
      "Good knowledge of VLSI process and device characteristics",
      "Experience doing physical design targeted to the 7nm/16nm FinFet process",
      "Good knowledge of cell libraries’ various views and models",
      "Good understanding of static timing analysis (STA), EM/IR, and sign-off",
      "Strong hands-on experience with Chip Level / Sub-chip level floor planning, > partition, pin assignment, Power planning, Bump Planning, Pad Ring Creation, > Block level physical implementation, timing closure, physical verification, Chip level integration of different sub-blocks, and custom macros/IPs, Timing, IR/EM analysis and closure, Physical Verification – block and chip level",
      "EDA Tool Expertise: Innovus, Tempus/PrimeTime-SI, Voltus/RedHawk, StarXT/Quantus, Calibre,LEC, etc",
      "Good software and scripting skills (Python, tcl)",
      "Good communication skills and the ability and desire to work as part of a team",
      "Self-driven individual and an excellent team player",
      "Good communication abilities",
    ],
  },
  "dft-engineer-all-levels": {
    location: "Bangalore",
    experience: " 1-15 Years",
    "Preferred Education": ["B.Tech/M.Tech"],
    "Area of Expertise": [
      "Knowledge of various DFT technologies for example JTAG, Mbist, Scan",
      "Experience with RTL Coding- Verilog, System Verilog, VHDL",
      "Excellent in Scripting languages for example Perl/Tcl/Tk/Python",
      "Exposure to Change Management Software for example Perforce is a strong plus",
      "Excellent problem-solving and analytical skills",
      "Excellent communication, teamwork, and networking skills",
      "Experience with Industry Standard DFT EDA Tools is preferred",
    ],
  },
  "design-verification-engineer-all-levels": {
    location: "Bangalore",
    experience: " 1-15 Years",
    "Preferred Education": ["B.Tech/M.Tech"],
    "Area of Expertise": [
      "Strong SV/UVM fundamentals",
      "Experience of building Test benches from scratch",
      "Assertions driven verification",
      "Coverage driven verification",
      "Any protocol Experience",
      "Highly motivated, self-starter with good interpersonal skills and a strong team player",
      "Excellent communication, critical thinking, and problem-solving skills",
    ],
  },
  "rtl-fpga-engineer-all-levels": {
    location: "Bangalore",
    experience: " 1-15 Years",
    "Preferred Education": ["B.Tech/M.Tech"],
    "Area of Expertise": [
      "RTL / Logic Development in VHDL/Verilog",
      "Full FPGA development flow from logic design, place route, timing analysis closure",
      "Experience with advanced Xilinx/Intel FPGA families and the Xilinx/Intel development tools including Vivado/Quartus",
      "Making testbenches for functional simulation of IP/FPGA design",
      "Troubleshooting and debugging FPGA implementations on boards",
      "Knowledge of any scripting language such as bash/Perl/python",
      "Highly motivated, self-starter with good interpersonal skills and a strong team player",
      "Excellent communication, critical thinking, and problem-solving skills",
    ],
  },
  "rtl-asic-engineer-all-levels": {
    location: "Bangalore",
    experience: " 1-15 Years",
    "Preferred Education": ["Any Degree is fine"],
    "Area of Expertise": [
      "Experienced in RTL design using Verilog / System Verilog",
      "ASIC designers with experiences in all aspects of RTL design flow from Specification/Microarchitecture definition to design and verification, Timing Analysis, DFT and Implementation",
      "Integration, RTL signoff tools, UPF/Low power signoff and CDC/RDC, Lint",
      "Strong domain knowledge of Clocking, System modes, Power management, debug, interconnect, safety, security, and other architectures",
      "Highly motivated, self-starter with good interpersonal skills and a strong team player",
      "Excellent communication, critical thinking, and problem-solving skills",
    ],
  },
};

export {
  navigationItems,
  gridData,
  numbersArray,
  cardArray,
  quickLink,
  informationalLink,
  caseStudies,
  servicesLinks,
  servicesPageData,
  pressMediaImages,
  testimonialsData,
  jobsTitles,
  jobData,
};
