"use client";
import { useEffect, useState } from "react";

const Counter = ({ number }: { number: number }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < number) {
        setCount((prev) => prev + 1);
      } else {
        clearInterval(interval);
      }
    }, 4000 / number);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className="text-5xl md:text-7xl font-semibold my-4">{count}+</div>
  );
};

export default Counter;
