import { useEffect } from "react";

type Props = {
  selectedImage: string;
  modalIsOpen: boolean;
  handleModelOpen: Function;
};

const ImageOpenModal = ({
  selectedImage,
  modalIsOpen,
  handleModelOpen,
}: Props) => {
  useEffect(() => {
    const closeModalOnClick = (e: any) => {
      const ids = ["cont", "blCvr"];
      if (ids.includes(e.target.id)) {
        handleModelOpen(false);
      }
    };
    document.addEventListener("click", closeModalOnClick);
    return () => {
      document.removeEventListener("click", closeModalOnClick);
    };
  }, []);
  return (
    <div className="w-[100vw] h-[100vh] " id="cont">
      <div
        id="blCvr"
        className="inset-0 z-20 bg-black fixed opacity-80 w-[100vw] h-[100vh] top-0 left-0"
      ></div>
      <div id="crossImageDiv">
        <img
          src="/crossIcon.png"
          alt="cross icon"
          className="w-[30px] h-[30px] z-30 fixed top-3 right-10 cursor-pointer"
          onClick={() => handleModelOpen(false)}
        />
      </div>
      <img
        id="mainImgDiv"
        className="w-[95%] z-30 lg:w-[560px] fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-auto lg:h-[792px] object-cover"
        src={selectedImage}
        alt="Press and media image"
      />
    </div>
  );
};

export default ImageOpenModal;
