import {
  BiLogoFacebook,
  BiLogoLinkedinSquare,
  BiLogoTwitter,
} from "react-icons/bi";
import Footer from "../components/Footer";
import Footerlink from "../components/Footerlink";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import emailjs from "@emailjs/browser";

const initialState = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

const ContactPage = () => {
  const [inputData, setInputData] = useState(initialState);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [messageSentStatus, setMessageSentStatus] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(false);

  useEffect(() => {
    console.log(phoneValidation);
  }, [phoneValidation]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneValidation) {
      return;
    }
    setSendEmailLoading(true);
    const templateParams = {
      to_name: "Blueberry",
      from_name: inputData.name,
      from_email: inputData.email,
      from_mobile: inputData.phone,
      message: inputData.message,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setMessageSentStatus("ok");
          setInputData(initialState);
        },
        (error) => {
          setMessageSentStatus("error");
        }
      )
      .catch((err) => {
        console.log(err);
        setMessageSentStatus("error");
      })
      .finally(() => {
        setSendEmailLoading(false);
      });
  };
  const verifyPhoneNumber = (value: string) => {
    if (value.length === 2) {
      setPhoneValidation(false);
      return true;
    } else if (value.length !== 12) {
      setPhoneValidation(false);
      return "Invalid Phone";
    } else {
      setPhoneValidation(true);
      return true;
    }
  };
  return (
    <>
      <Navbar />
      <section className="bg-[#045e9a] min-h-[60vh]">
        <div className="max-w-[1200px] m-auto pt-[120px] pb-[20px] flex flex-col justify-center items-center lg:flex-row">
          <div className="w-[95%] lg:w-1/2 min-h-[50vh] text-white px-5 py-5">
            <h2 className="text-[45px] md:text-[55px] xl:text-[80px] font-heading text-center lg:text-left">
              Contact
            </h2>
            <p className="text-[27px] md:text-[32px] lg:text-[32px] font-heading mt-4 lg:mt-7">
              Feel free to contact us for any questions and doubts
            </p>
            <p className="text-[14px] md:text-[17px] lg:text-[16px] font-montserrat mt-4 lg:mt-7">
              We work with ambitious leaders who want to define the future, not
              hide from it.
            </p>
            <p className="uppercase font-heading font-semibold mt-4 lg:mt-7">
              Keep in touch
            </p>
            <div className="flex gap-2 text-2xl md:text-3xl mt-4 relative left-[-5px]">
              <Footerlink link="/">
                <BiLogoFacebook />
              </Footerlink>
              <Footerlink link="/">
                <BiLogoTwitter />
              </Footerlink>
              <Footerlink link="/">
                <BiLogoLinkedinSquare />
              </Footerlink>
            </div>
          </div>
          <div className="w-[95%] lg:w-1/2 min-h-[50vh] bg-white flex flex-col lg:p-5 lg:px-7 gap-6">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col p-5 px-7 gap-5"
            >
              <input
                type="text"
                required
                placeholder="Your Name"
                className="border w-full p-[10px] h-[48px] bg-[#fafafa] rounded focus:outline-outline"
                name="name"
                value={inputData.name}
                onChange={(e) =>
                  setInputData({ ...inputData, name: e.target.value })
                }
              />
              <PhoneInput
                country={"in"}
                enableSearch={true}
                containerClass={"w-full"}
                inputClass={
                  "border p-[10px] h-[48px] bg-[#fafafa] rounded numberInput"
                }
                value={inputData.phone}
                onChange={(phone) =>
                  setInputData({ ...inputData, phone: phone })
                }
                isValid={(value) => verifyPhoneNumber(value)}
                placeholder="Contact Number"
                inputProps={{
                  name: "phone",
                  required: true,
                }}
              />

              <input
                type="email"
                required
                placeholder="Email Address"
                className="border w-full p-[10px] h-[48px] bg-[#fafafa] rounded focus:outline-outline"
                value={inputData.email}
                onChange={(e) =>
                  setInputData({ ...inputData, email: e.target.value })
                }
              />
              <textarea
                className="border w-full p-[10px] bg-[#fafafa] rounded focus:outline-outline"
                name="message"
                placeholder="Message"
                id="message"
                rows={4}
                required
                value={inputData.message}
                onChange={(e) =>
                  setInputData({ ...inputData, message: e.target.value })
                }
              ></textarea>
              <div className="w-full">
                <button
                  type="submit"
                  className="uppercase h-[48px] border-[2px] border-[#07609b] w-[180px] text-[#07609b] font-semibold hover:bg-[#07609b] hover:text-white"
                >
                  {sendEmailLoading ? "Sending Message.." : "Send Messsage"}
                </button>
                <p className="mt-2 text-green-600 font-montserrat font-semibold">
                  {messageSentStatus === "ok"
                    ? "Email has been sent successfully!"
                    : messageSentStatus === "error"
                    ? "Something went wrong please try again!"
                    : ""}
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section>
        <div className="max-w-[1200px] m-auto flex flex-col justify-center items-center lg:flex-row gap-5 lg:gap-3 py-9">
          <div className="w-[90%] lg:w-[50%] min-h-[20vh]">
            <div className="w-full flex justify-start items-center gap-3">
              <IoLocationSharp className="text-[#045e9a]" />
              <h3 className="font-semibold text-[15px] font-heading">
                HEAD OFFICE
              </h3>
            </div>
            <p className="px-6 text-[16px] md:text-[19px] mt-2">
              13/36, 3rd Cross, Koramangala 6th Block, Venkat Reddy Layout, 80
              Feet Road, Near Sony World Signal, Bengaluru, Karnataka - 560 095.
            </p>
          </div>
          <div className="w-[90%] lg:w-[25%] min-h-[20vh]">
            <div className="w-full flex justify-start items-center gap-3">
              <MdEmail className="text-[#045e9a]" />
              <h3 className="font-semibold text-[15px] font-heading">EMAIL</h3>
            </div>
            <div className="px-6">
              <p className="font-bold">Business purpose:</p>
              <p>sales@blueberrysemi.com</p>
              <p className="font-bold">Information seeking:</p>
              <p>hr@blueberrysemi.com</p>
              <p className="font-bold">Career:</p>
              <p>careers@blueberrysemi.com</p>
            </div>
          </div>
          <div className="w-[90%] lg:w-[25%] min-h-[20vh]">
            <div className="w-full flex justify-start items-center gap-3">
              <FaPhone className="text-[#045e9a]" />
              <h3 className="font-semibold text-[15px] font-heading">PHONE</h3>
            </div>
            <p className="px-6 text-[16px] md:text-[19px] mt-2">
              +91 80 48663700
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="max-w-[1200px] m-auto flex justify-center items-center py-6 pb-24">
          <iframe
            title="map"
            className="w-[90%] lg:w-full min-h-[480px]"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1944.2574722444888!2d77.627763!3d12.938867!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae144161348a3f%3A0x3464ac56cc624f43!2sBlueberry%20Semiconductors!5e0!3m2!1sen!2sus!4v1704694937133!5m2!1sen!2sus"
            loading="lazy"
          ></iframe>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactPage;
