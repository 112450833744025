import PageHeaders from "../components/PageHeaders";
import { Link, useLocation } from "react-router-dom";
import { servicesPageData, servicesLinks, caseStudies } from "../constants";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FaCheck } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import StudyCard from "../components/StudyCard";

const ServicesPage = () => {
  const location = useLocation();
  const pagePath: { path: string; title: string } =
    servicesPageData[location.pathname.split("/").at(-1)];
  return (
    <>
      <Navbar />
      <div>
        <PageHeaders
          url="/assets/services-background.jpg"
          title={pagePath.title}
        />
        <section>
          <div className="flex flex-col md:flex-row justify-center items-center md:items-start max-w-[1200px] mx-auto gap-6 p-5 md:p-10 my-10">
            <div className="w-[95%] sm:w-[380px] min-h-[50vh] flex flex-col gap-1">
              <div className="w-full h-fit flex flex-col gap-1">
                {servicesLinks.map((ele, ind) => (
                  <Link
                    to={ele.path}
                    key={ind}
                    className="h-[60px] bg-[#045e9a] px-4 flex justify-start items-center text-[17.5px] text-white"
                  >
                    <p className="">{ele.text}</p>
                  </Link>
                ))}
              </div>

              <div className="w-full h-[495px]">
                <img
                  src="/assets/services/Brochure-Pg1.png"
                  alt="Brochure Image"
                  className="h-[485px] w-full object-cover"
                />
              </div>
            </div>

            {/* Right Section */}
            {selectPage(pagePath)}
          </div>
        </section>

        {/* Related case study section */}
        <section>
          <div className="max-w-[1200px] m-auto">
            <p className="text-4xl md:text-6xl font-heading">
              Related Casestudy
            </p>
          </div>
          {/* Case Study crousal */}
          <div className="max-w-[1200px] m-auto p-5">
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              modules={[Pagination]}
              pagination={{ clickable: true }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {caseStudies.map(
                (ele, ind) =>
                  ele.head == pagePath.title && (
                    <SwiperSlide key={ind}>
                      <StudyCard data={ele} />
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ServicesPage;

function selectPage(pagePath: { path: string; title: string }) {
  switch (pagePath.path) {
    case "/silicon-engineering": {
      return htmlForSiliconEng(pagePath.title);
    }
    case "/fpga-design": {
      return htmlForFpga(pagePath.title);
    }
    case "/embedded-software": {
      return htmlForEmbededSoft(pagePath.title);
    }
    case "/hardware-design": {
      return htmlForHardwareDesign(pagePath.title);
    }
    case "/machine-learning": {
      return htmlForMachineLearning(pagePath.title);
    }
    case "/turnkey-solutions": {
      return htmlForTurnkeySol(pagePath.title);
    }
  }
}

function htmlForSiliconEng(title: string) {
  return (
    <div className="w-[95%] sm:w-[calc(100%-380px)] min-h-[50vh] flex flex-col gap-4">
      {/* Header */}
      <h2 className="text-4xl md:text-5xl font-heading">{title}</h2>
      {/* Desc */}
      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry is working with US customers, India development centres in the
        area of IP design, SoC integration.
      </p>
      {/* Image section */}
      <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-0 mt-4">
        <div className="w-full lg:w-[54%]">
          <p className="font-heading text-[19px] font-semibold">
            Our offerings in ASIC/SoC design are as follows:
          </p>
          <ul className="font-montserrat text-[15.8px] mt-2">
            {[
              "Micro-architecture chip design",
              "RTL design",
              "Functional verification",
              "SoC integration",
              "ASIC IP development",
            ].map((ele, ind) => (
              <li
                key={ind}
                className="flex justify-start items-center gap-2 mt-1"
              >
                <div className="w-[5%]">
                  <FaCheck className="text-[18px]" />
                </div>
                <p className="w-[95%]">{ele}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:w-[46%] flex items-start">
          <img
            className="w-full object-cover"
            src="/assets/services/silicon_engineering.jpeg"
            alt="Embeded Image"
          />
        </div>
      </div>

      {/* 3rd desc section */}
      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Key strength of Blueberry is in Verification services. We were part of a
        complex SoC intended for AI application designed around multicore ARM
        CPU and Graphics processor interconnected with proprietary interconnect.
        More than 60% of ongoing projects are for Verification of IPs, Subsystem
        and SoC.
      </p>

      {/* 4th pointer section */}
      <div className="w-full">
        <p className="font-heading text-[19px] font-semibold">
          Our Verification services include following.
        </p>
        <ul className="font-montserrat text-[15.8px] mt-2">
          {[
            "SoC/IP Functional Verification",
            "Experience on ARM Cortex series Processor verification",
            "Analog Mixed Signal Verification",
            "IP Development and Verification",
            "EDA Tool Validation",
            "Pre-Silicon and Post-Silicon Validation/Verification",
          ].map((ele, ind) => (
            <li
              key={ind}
              className="flex justify-start items-center gap-2 mt-1"
            >
              <div className="w-[5%]">
                <FaCheck className="text-[18px]" />
              </div>
              <p className="w-[95%]">{ele}</p>
            </li>
          ))}
        </ul>
      </div>

      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry also offers skilled engineering resources for RTL to GDS and
        Custom chip design.
      </p>
    </div>
  );
}

function htmlForFpga(title: string) {
  return (
    <div className="w-[95%] sm:w-[calc(100%-380px)] min-h-[50vh] flex flex-col gap-4">
      {/* Header */}
      <h2 className="text-4xl md:text-5xl font-heading">{title}</h2>
      {/* Desc */}
      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry is working with US customers, India development centres in the
        area of IP design, SoC integration. <br /> An important trend seen in
        recent time is implementing DNN models, CNN models on FPGA for real time
        AI applications requiring time bound complex computation in image,
        video, sensor data analytics. Selection of right FPGA family is the
        first step in this product design. The FPGA design now is not just
        limited to microarchitecture design of the logic till implementation.
        Because there is a built-in CPU cores, and peripherals interconnected by
        AXI or similar on-chip bus, team has to take care of system design
        engineering aspects as well right from device driver, firmware design to
        application software design and board design & validation.
      </p>
      {/* Image section */}
      <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-0 mt-4">
        <div className="w-full lg:w-[54%]">
          <p className="font-heading text-[19px] font-semibold">
            Blueberry offer following services in FPGA design.
          </p>
          <ul className="font-montserrat text-[15.8px] mt-2">
            {[
              "FPGA Selection",
              "Micro-architecture design",
              "FPGA-SoC design integration",
              "RTL Coding to timing & area driven PAR",
              "ASIC prototyping, validation",
              "Functional Verification",
            ].map((ele, ind) => (
              <li
                key={ind}
                className="flex justify-start items-center gap-2 mt-1"
              >
                <div className="w-[5%]">
                  <FaCheck className="text-[18px]" />
                </div>
                <p className="w-[95%]">{ele}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:w-[46%] flex items-start">
          <img
            className="w-full object-cover"
            src="/assets/services/fpga.jpeg"
            alt="Embeded Image"
          />
        </div>
      </div>
    </div>
  );
}

function htmlForEmbededSoft(title: string) {
  return (
    <div className="w-[95%] sm:w-[calc(100%-380px)] min-h-[50vh] flex flex-col gap-4">
      {/* Header */}
      <h2 className="text-4xl md:text-5xl font-heading">{title}</h2>
      {/* Desc */}
      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry has extensive experience in the areas of core embedded
        software, right from device driver, to Middleware to application layer
        software development, essentially covering all components a typical BSP.
        We also have developed bare metal compact & efficient firmware
        application around microcontrollers for Industrial real-time data
        acquisition & control systems.
      </p>
      {/* Image section */}
      <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-0 mt-4">
        <div className="w-full lg:w-[54%]">
          <p className="font-heading text-[19px] font-semibold">
            Blueberry offers following services in embedded software.
          </p>
          <ul className="font-montserrat text-[15.8px] mt-2">
            {[
              "Board Bring-up, BSP, Device Driver and Firmware Development",
              "OS Porting - Android, Linux, Windows, QNX",
              "Firmware porting",
              "Power and Footprint Optimization",
              "Sensor Integration for IIoT",
            ].map((ele, ind) => (
              <li
                key={ind}
                className="flex justify-start items-center gap-2 mt-1"
              >
                <div className="w-[5%]">
                  <FaCheck className="text-[18px]" />
                </div>
                <p className="w-[95%]">{ele}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:w-[46%] flex items-start">
          <img
            className="w-full object-cover"
            src="/assets/services/embedded.jpeg"
            alt="Embeded Image"
          />
        </div>
      </div>
    </div>
  );
}

function htmlForHardwareDesign(title: string) {
  return (
    <div className="w-[95%] sm:w-[calc(100%-380px)] min-h-[50vh] flex flex-col gap-4">
      {/* Header */}
      <h2 className="text-4xl md:text-5xl font-heading">{title}</h2>
      {/* Desc */}
      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry has developed hardware for Industrial Automation, Defence,
        Home automation and Consumer domain. Blueberry has capability to design
        low power mixed-signal boards, Sensor interface, high speed multi-layer
        boards.
      </p>
      {/* Image section */}
      <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-0 mt-4">
        <div className="w-full lg:w-[54%]">
          <p className="font-heading text-[19px] font-semibold">
            Following are services in Hardware design.
          </p>
          <ul className="font-montserrat text-[15.8px] mt-2">
            {[
              "Architecture and Design",
              "Component Benchmarking and Selection",
              "Multicore, multiprocessor, GPU, FPGA based HW designs",
              "Multilayer PCB design",
              "Multilayer PCB design",
              "Re-engineering to tackle BoM optimization, component obsolescence management",
            ].map((ele, ind) => (
              <li
                key={ind}
                className="flex justify-start items-center gap-2 mt-1"
              >
                <div className="w-[5%]">
                  <FaCheck className="text-[18px]" />
                </div>
                <p className="w-[95%]">{ele}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:w-[46%] flex items-start">
          <img
            className="w-full object-cover"
            src="/assets/services/hardwaredesign.jpeg"
            alt="Embeded Image"
          />
        </div>
      </div>
    </div>
  );
}

function htmlForMachineLearning(title: string) {
  return (
    <div className="w-[95%] sm:w-[calc(100%-380px)] min-h-[50vh] flex flex-col gap-4">
      {/* Header */}
      <h2 className="text-4xl md:text-5xl font-heading">{title}</h2>
      {/* Desc */}
      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Machine Learning (ML), a subset of AI technology, helps use historic
        data and generate intelligent response. To achieve this, a DNN is
        designed and trained using historic data. This network generates
        intelligence response for subsequent fresh input sequence. Earlier this
        was done in cloud based AI software running on powerful servers. With
        availability of multiple cpu cores within chip, a dedicated hardware
        logic implementing DNNs and yet consume low power , it is now possible
        to implement ML on embedded devices. Advantage of this is now AI-ML
        algorithm can be implemented right in the control system, edge device
        interfacing IoT sensor.
      </p>
      {/* Image section */}
      <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-0 mt-4">
        <div className="w-full lg:w-[54%]">
          <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
            Applications of embedded ML are numerous. User can be provided
            improved personal assistance on smartphones, wearables. It can be
            used to provide improved drive experience in automobile, Improved
            production efficiency & predictive maintenance in Industrial
            production setup.
          </p>
        </div>
        <div className="lg:w-[46%] flex items-start">
          <img
            className="w-full object-cover"
            src="/assets/services/machinelearning.jpeg"
            alt="Embeded Image"
          />
        </div>
      </div>

      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry has capability to model DNN, deploy on embedded device having
        multiple CPU cores or dedicated compute hardware. Blueberry has done
        projects in designing compiler tool chain for application specific
        hardware for DNN. The flexible compiler developed, compiles DNN models
        developed in any of the popular DNN modelling frameworks. The framework,
        methodology/technology used are ONNX, MLIR, LLVM.
      </p>

      <div className="w-full">
        <p className="font-heading text-[19px] font-semibold">
          Software Solutions:
        </p>
        <ul className="font-montserrat text-[15.8px] mt-2">
          {[
            "Compiler Toolchain for AI-DNN processor",
            "DNN Model Development & Deployment",
            "AI/ML Enabled Solutions & Services",
          ].map((ele, ind) => (
            <li
              key={ind}
              className="flex justify-start items-center gap-2 mt-1"
            >
              <div className="w-[5%]">
                <FaCheck className="text-[18px]" />
              </div>
              <p className="w-[95%]">{ele}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function htmlForTurnkeySol(title: string) {
  return (
    <div className="w-[95%] sm:w-[calc(100%-380px)] min-h-[50vh] flex flex-col gap-4">
      {/* Header */}
      <h2 className="text-4xl md:text-5xl font-heading">{title}</h2>
      {/* Desc */}
      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        SoCs with dual, quad processor cores came to market quite some time
        back. In addition such SoC now a days has dedicated onchip compute
        hardware, either in the form of graphic coprocessor or programmable
        neural network block or DSP processor. This opens up host of new
        applications, especially on embedded device. For example existing AI/ML
        algorithm can run faster enabling realtime object/face recognition.
        Compute intensive software can be run faster by engaging & loading all
        cores equally. IoT node designed around such SoC can generate realtime
        intelligent response utilizing historic data.
      </p>
      {/* Image section */}
      <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-0 mt-4">
        <div className="w-full lg:w-[54%]">
          <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
            The legacy application software running on the system are
            sequential, designed to run around single processor core. The
            approach to develop application programm was still sequential in
            nature in the absence of proper framework to develop parallel
            software. An OpanMP is one of the popular framework available now to
            enable parallel software development and off-course a mindset to
            visualize parallelizing software block is very much needed to truly
            leverage it.
          </p>
        </div>
        <div className="lg:w-[46%] flex items-start">
          <img
            className="w-full object-cover"
            src="/assets/services/turnkeysolution.jpeg"
            alt="Embeded Image"
          />
        </div>
      </div>

      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry has expertise in implementing ML algorithm. Blueberry has been
        doing research in parallel software development under OpenMP framework
      </p>

      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Our Software solution offerings are as below:
      </p>

      <div className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        <p className="font-bold">Software Solutions:</p>
        <ol className="list-disc ml-9">
          <li>OpenMP based Framework for Parallel Software</li>
          <li>Enabling existing solution with IIoT and AI/ML</li>
          <li>Data Science & Analytics</li>
        </ol>
      </div>

      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        Blueberry team has extensive experience in developing realtime robust
        Industrial Data Acquisition & Control around microcontrollers and
        processor systems. Our capabilities & offerings include below.
      </p>

      <div className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        <p className="font-bold">Industrial Automation:</p>
        <ol className="list-disc ml-9">
          <li>SCADA based solution</li>
          <li>
            Fieldbus/Modbus/ RTU based implementation MES Configuration and
            Deployment
          </li>
          <li>Data Analytics and Alarm Generation</li>
        </ol>
      </div>

      <p className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        The conventional IoT system now can be re-engineered leveraging new
        technologies like ML. The marriage of two opens up new possibilities in
        Industrial IoT, Sensor Hub. We have home grown IoT software stack that
        can be customized across processor platform, communication technologies.
        Our capabilities & offerings in IoT enabled solutions are as below.
      </p>

      <div className="text-[14px] md:text-[16px] leading-7 font-montserrat tracking-wide">
        <p className="font-bold">IoT based solutions:</p>
        <ol className="list-disc ml-9">
          <li>Edge device development</li>
          <li>
            IoT Platform development reusing home-grown Scalable, Customizable
            stack for Industrial IoT
          </li>
          <li>Smart sensor solutions with ML</li>
        </ol>
      </div>
    </div>
  );
}
