const PageHeaders = ({
  url,
  title,
  desc,
  buttonTitle,
  bg,
}: {
  url?: string;
  title: string;
  desc?: string;
  buttonTitle?: string;
  bg?: string;
}) => {
  return (
    <header>
      <div
        className={`bg-center bg-no-repeat bg-cover h-[25vh] md:h-[40vh] ${
          bg && "bg-[" + bg + "]"
        }`}
        style={{
          backgroundImage: `url('${url}')`,
        }}
      >
        <div className=" h-[25vh] md:h-[40vh] absolute inset-0 bg-black opacity-40"></div>
        <div className="h-[25vh] md:h-[35vh] absolute w-full flex justify-center items-center z-10">
          <div>
            <h1 className="text-4xl md:text-6xl text-gray-100 font-heading text-center capitalize">
              {title}
            </h1>
            {desc && (
              <p className="text-white text-center font-montserrat">{desc}</p>
            )}
            {buttonTitle && (
              <div className="w-full flex justify-center mt-3 ">
                <button className="text-white w-[150px] h-[45px] border-[2px] border-white uppercase hover:bg-white hover:text-black transition-all duration-200 ease-in-out">
                  {buttonTitle}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default PageHeaders;
