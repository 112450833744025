import { navigationItems } from "../constants";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isCareersDropdownOpen, setCareersDropdownOpen] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [toggleOpenForJob, setToggleOpenForJob] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    toggleNavbar();
  };

  const toggleCareersDropdown = () => {
    setCareersDropdownOpen(!isCareersDropdownOpen);
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setNavWidth("");
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const [navWidth, setNavWidth] = useState("w-full");
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleNav = (width: any) => {
    setNavWidth(width);
  };
  const handleScroll = () => {
    if (window.scrollY > 0) {
      closeMobileMenu();
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  const toggleNavbar = () => {
    setIsScrolled(false);
    setNavWidth("bg-black backdrop-filter lg:bg-opacity-70");
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <nav
      className={`px-4 py-2 w-full fixed text-xl z-20 ${navWidth} flex justify-end items-center ${
        isScrolled ? "overflow-hidden" : "right-none"
      }`}
    >
      {isScrolled && (
        <>
          <div className="hidden lg:flex justify-end items-center">
            <button
              onClick={toggleNavbar}
              className="text-white hover:text-gray-300 border bg-gray-800 border-ftbdr flex justify-center items-center p-3 rounded-full "
            >
              <FaBars />
            </button>
          </div>
          <div className="lg:hidden flex justify-end items-center">
            <button
              onClick={toggleMobileMenu}
              className="text-white  hover:text-gray-300 border bg-gray-800 border-gray-500 flex justify-center items-center p-3 rounded"
            >
              {isMobileMenuOpen ? <AiOutlineClose /> : <FaBars />}
            </button>
          </div>
          <button
            onClick={scrollToTop}
            className="text-white hover:text-gray-300 border bg-gray-800 border-ftbdr flex justify-center items-center p-1 rounded-sm fixed bottom-10 text-2xl"
          >
            <HiOutlineChevronUp />
          </button>
        </>
      )}
      {!isScrolled && (
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/">
            <img
              className="h-[35px] object-cover"
              src="/logo.svg"
              alt="Logo"
              decoding="async"
            />
          </Link>
          <div className={" hidden lg:flex space-x-6 items-center"}>
            {navigationItems.map((item) => (
              <div key={item.text}>
                {item.subItems ? (
                  <div
                    className="relative group cursor-pointer"
                    onMouseEnter={
                      item.text === "Careers"
                        ? toggleCareersDropdown
                        : toggleServicesDropdown
                    }
                    onMouseLeave={
                      item.text === "Careers"
                        ? toggleCareersDropdown
                        : toggleServicesDropdown
                    }
                  >
                    <span
                      className={`
                            ${
                              (item.text === "Careers" &&
                                (isCareersDropdownOpen ||
                                  item.subItems.find(
                                    (subItem) =>
                                      subItem.path === location.pathname
                                  ))) ||
                              (item.text === "Services" &&
                                (isServicesDropdownOpen ||
                                  item.subItems.find(
                                    (subItem) =>
                                      subItem.path === location.pathname
                                  )))
                                ? "text-white"
                                : "text-gray-300"
                            }
                                group-hover:text-white flex gap-2 font-heading`}
                    >
                      {item.text.toLocaleUpperCase()}
                      <HiOutlineChevronDown />
                    </span>

                    {(item.text === "Careers" && isCareersDropdownOpen) ||
                    (item.text === "Services" && isServicesDropdownOpen) ? (
                      <div className="absolute left-0 bg-blue-900 z-10 text-sm">
                        <ul>
                          {item.subItems.map((subItem) => (
                            <li
                              onMouseEnter={
                                subItem?.subItems
                                  ? () => setToggleOpenForJob(!toggleOpenForJob)
                                  : () => {}
                              }
                              onMouseLeave={
                                subItem?.subItems
                                  ? () => setToggleOpenForJob(!toggleOpenForJob)
                                  : () => {}
                              }
                              key={subItem.path}
                              className={`${
                                subItem.path === location.pathname
                                  ? "text-primary bg-white"
                                  : "bg-primary text-white"
                              } hover:text-primary relative hover:bg-white whitespace-nowrap p-4 w-full transition duration-300`}
                            >
                              <Link to={subItem.path}>
                                {subItem.text.toLocaleUpperCase()}
                              </Link>
                              {subItem?.subItems && toggleOpenForJob && (
                                <div className="bg-primary text-white absolute left-[100%] top-0 w-fit h-full">
                                  {subItem.subItems.map((ele, ind) => (
                                    <Link to={ele.path}>
                                      <p
                                        key={ind}
                                        className={`bg-primary text-white hover:text-primary relative hover:bg-white whitespace-nowrap p-4 w-full transition duration-300 uppercase`}
                                      >
                                        {ele.text}
                                      </p>
                                    </Link>
                                  ))}
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <span
                    className={`${
                      location.pathname === item.path
                        ? "text-white"
                        : "text-gray-300"
                    } hover:text-white`}
                  >
                    <Link to={item.path} className="font-heading">
                      {item.text.toLocaleUpperCase()}
                    </Link>
                  </span>
                )}
              </div>
            ))}
          </div>
          <button
            onClick={toggleMobileMenu}
            className="text-white lg:hidden hover:text-gray-300 border bg-gray-800 border-gray-500 flex justify-center items-center p-3 rounded"
          >
            {isMobileMenuOpen ? <AiOutlineClose /> : <FaBars />}
          </button>
          {isMobileMenuOpen && (
            <div className="lg:hidden absolute top-20 left-0 right-0 bg-white p-4 text-[16px]">
              <ul className="space-y-4">
                {navigationItems.map((item) => (
                  <div key={item.text}>
                    {item.subItems ? (
                      <div
                        className="relative group "
                        onClick={
                          item.text === "Careers"
                            ? toggleCareersDropdown
                            : toggleServicesDropdown
                        }
                      >
                        <span
                          className={`${
                            (item.text === "Careers" &&
                              isCareersDropdownOpen) ||
                            (item.text === "Services" && isServicesDropdownOpen)
                              ? "text-primary"
                              : "text-black"
                          } group-hover:text-primary w-full flex justify-between items-center`}
                        >
                          {item.text.toLocaleUpperCase()}
                          <HiOutlineChevronDown />
                        </span>

                        {(item.text === "Careers" && isCareersDropdownOpen) ||
                        (item.text === "Services" && isServicesDropdownOpen) ? (
                          <div className=" p-4">
                            <ul className="space-y-2">
                              {item.subItems.map((subItem) => (
                                <li key={subItem.path}>
                                  <span
                                    onClick={closeMobileMenu}
                                    className="text-black hover:text-primary flex justify-between"
                                  >
                                    {subItem.text.toLocaleUpperCase()}
                                    {subItem.subItems && (
                                      <HiOutlineChevronDown />
                                    )}
                                  </span>
                                  {subItem.subItems && (
                                    <ul className="space-y-2 p-4">
                                      {subItem.subItems.map((ele, ind) => (
                                        <li key={subItem.path}>
                                          <Link to={ele.path}>
                                            <span
                                              onClick={closeMobileMenu}
                                              className="text-black hover:text-primary"
                                            >
                                              {ele.text.toLocaleUpperCase()}
                                            </span>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <Link to={item.path}>
                        <span
                          className={`${
                            location.pathname === item.path
                              ? "text-primary"
                              : "text-black hover:text-primary"
                          }`}
                        >
                          {item.text.toLocaleUpperCase()}
                        </span>
                      </Link>
                    )}
                  </div>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
