type Props = { data: Record<string, any> };

const Testimonial = ({ data }: Props) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <img
        src="/double-quotes.png"
        className="w-[18px] h-[18px]"
        alt="left double quotation mark"
      />
      <p className="text-[16px] font-montserrat">{data.message}</p>
      <div className="flex gap-4 mt-5">
        <img
          src={data.img}
          alt="avatar"
          className="w-[32px] h-[32px] rounded-full"
        />
        <div className="flex flex-col gap-0">
          <p className="font-medium">{data.name}</p>
          <p>{data.des}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
