import { useState } from "react";
import Navbar from "../components/Navbar";
import PageHeaders from "../components/PageHeaders";
import Footer from "../components/Footer";
import { pressMediaImages } from "../constants";
import ImageOpenModal from "../components/ImageOpenModal";

type Props = {};

const PressAndMedia = (props: Props) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [isModelOpen, setIsModelOpen] = useState(false);
  const handleSelectImage = (e: any) => {
    const target = e.target;
    if (target?.tagName == "IMG" && target?.src) {
      setSelectedImage(target.src);
      setIsModelOpen(true);
    }
  };
  return (
    <>
      <Navbar />
      <div>
        <PageHeaders
          url="/assets/press_media/Banner.png"
          title="Case Studies"
        />
        <div
          className="max-w-[1200px] m-auto min-h-[100vh] gap-10 py-28 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center"
          onClick={handleSelectImage}
        >
          {pressMediaImages.map((ele, ind) => (
            <img
              key={ind}
              src={ele}
              alt="press and media image"
              className="w-[95%] md:w-[366px] h-[520px] cursor-pointer"
            />
          ))}
        </div>
        {isModelOpen && (
          <ImageOpenModal
            selectedImage={selectedImage}
            handleModelOpen={setIsModelOpen}
            modalIsOpen={isModelOpen}
          />
        )}
        <Footer />
      </div>
    </>
  );
};

export default PressAndMedia;
