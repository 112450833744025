import { Link } from "react-router-dom";

const Footerlink = ({
  link,
  children,
}: {
  link: string;
  children: React.ReactNode;
}) => {
  return (
    <Link
      to={link}
      target="_blank"
      className="underline hover:text-primary hover:no-underline transition duration-300"
    >
      {children}
    </Link>
  );
};

export default Footerlink;
