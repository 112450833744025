import { Link } from "react-router-dom";
import React from "react";

const Homecard = ({ data }) => {
  return (
    <div className="transition duration-300 hover:brightness-125 w-[90%]">
      <Link to={data.url}>
        <img src={data.img} alt={data.title} className="my-4 w-full" />
        <h2 className="text-2xl font-bold font-heading mb-2">{data.title}</h2>
        <p className="leading-7">{data.desc}</p>
      </Link>
    </div>
  );
};

export default Homecard;
