import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PageHeaders from "../components/PageHeaders";
import StudyCard from "../components/StudyCard";
import { caseStudies } from "../constants";

const CaseStudies = () => {
  return (
    <>
      <Navbar />
      <div>
        <PageHeaders url="./assets/background-1.png" title="Case Studies" />
        <div className="bg-desc ">
          <div className="grid grid-cols-3 max-w-[1200px] mx-auto my-10 gap-8">
            {caseStudies.map((study, index) => (
              <StudyCard key={index} data={study} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CaseStudies;
