import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PageHeaders from "../components/PageHeaders";
import { useParams } from "react-router-dom";
import { jobData } from "../constants";
import ApplyJobModal from "../components/ApplyJobModal";
import { useState } from "react";

type Props = {};

const JobDescriptionPage = (props: Props) => {
  const { jobName } = useParams() as { jobName: string };
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Navbar />
      <PageHeaders url="" bg={"#045e9a"} title={jobName || ""} />
      <section>
        <div className="max-w-[1200px] m-auto py-16 px-3 text-[17px] flex flex-col gap-5 font-montserrat">
          <p>
            Location:{" "}
            <span className="font-bold">{jobData[jobName]?.location}</span>
          </p>
          <p>
            Location:{" "}
            <span className="font-bold">{jobData[jobName]?.experience}</span>
          </p>
          <div>
            <p className="font-bold">Preferred Education</p>
            <ul className="list-disc ml-10 mt-4 ">
              {jobData[jobName]?.["Preferred Education"].map(
                (ele: string, ind: number) => (
                  <li key={ind}>{ele}</li>
                )
              )}
            </ul>
          </div>
          <div>
            <p className="font-bold">Area of Expertise </p>
            <ul className="list-disc ml-10 mt-4 text-[14px] md:text-[17px]">
              {jobData[jobName]?.["Area of Expertise"].map(
                (ele: string, ind: number) => (
                  <li key={ind}>{ele}</li>
                )
              )}
            </ul>
          </div>
        </div>
        <div className="w-full flex justify-center items-center mb-7">
          <button
            onClick={() => setIsModalOpen(true)}
            className="uppercase w-[150px] h-[45px] text-[14px] border-[2px] border-[#045e9ae0] text-[#045e9ae0] hover:bg-[#045e9ae0] hover:text-white font-semibold"
          >
            Apply now
          </button>
        </div>
      </section>
      <Footer />

      {/* Modal to Apply */}
      {isModalOpen && <ApplyJobModal setIsModalOpen={setIsModalOpen} />}
    </>
  );
};

export default JobDescriptionPage;
