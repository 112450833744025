import { Link } from "react-router-dom";

const StudyCard = ({ data }: Record<string, any>) => {
  return (
    <div className="w-full h-[510px] bg-white shadow-lg rounded-lg flex flex-col hover:shadow-2xl transition duration-300">
      <div className="relative w-full">
        <img className="w-full h-60" src={data.src} alt={data.title} />
        <span className="rounded-2xl absolute top-2 right-2 bg-badge text-xs text-white px-4 py-2">
          {data.badge.toLocaleUpperCase()}
        </span>
      </div>
      <div className="flex-grow text-center py-8 font-heading font-bold text-2xl">
        {data.title}
      </div>
      <Link to={data.url}>
        <div className="font-heading font-semibold p-4 mt-auto hover:text-primary transition duration-300">
          READ MORE »
        </div>
      </Link>
      <div className="border-t border-gray-200 p-4 text-gray-400 text-xs rounded-lg">
        <span>{data.date}</span> - <span>{data.time}</span>
      </div>
    </div>
  );
};

export default StudyCard;
