import Counter from "../components/Counter";
import { Link } from "react-router-dom";
import { cardArray, gridData, numbersArray } from "../constants";
import Homecard from "../components/Homecard";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <div>
        <header
          className="h-[85vh] md:h-[50vh] lg:h-[90vh] w-full bg-center bg-no-repeat bg-cover relative"
          style={{
            backgroundImage: "url('./assets/banner-3.png')",
          }}
        >
          <div className=" h-full absolute inset-0 bg-black opacity-50"></div>
          <div className="absolute h-full w-full">
            <div className="flex flex-col justify-center lg:w-[80%] mx-auto h-full px-6 md:px-10">
              <div className="md:w-max ">
                <h1 className="text-6xl md:text-7xl text-gray-100 font-heading italic ">
                  SEMICONDUCTOR SERVICES
                </h1>
                <div className="text-desc text-lg py-2 leading-8">
                  <p>
                    Your design services partner to accelerate time-to-market.
                  </p>
                  <p>ODC | Onsite | Build-Operate-Transfer</p>
                </div>
                <div className="text-white flex flex-col md:flex-row mt-4 p-2 text-center gap-4 lg:gap-10 transition duration-300 font-semibold text-sm">
                  <Link
                    className="border-2 border-white py-2 w-full hover:bg-primary cursor-pointer transition duration-300"
                    to={"/services/silicon-engineering"}
                  >
                    <div>EXPLORE SERVICES</div>
                  </Link>

                  <Link
                    to={"/careers/jobs"}
                    className="border-2 border-white py-2 w-full hover:bg-primary cursor-pointer transition duration-300"
                  >
                    <div>JOIN US</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-[50%] py-10 lg:p-10 bg-gray-100">
              <div className="flex justify-end">
                <div className="p-5 lg:p-[8%]">
                  <div className="text-3xl md:text-4xl lg:text-5xl font-heading italic">
                    FUELLING SEMICONDUCTORS
                  </div>

                  <div className="border-2 border-gray-400 w-[50%] lg:w-[30%] rounded my-6"></div>

                  <p className="my-6 leading-7 font-medium">
                    We combine more than 20 years of SoC design services
                    experience with expertise in ARM, RISC-V, FPGA and ASIC
                    processors design, enabling clients to develop multiple
                    applications. With more than 300 successful tape-outs of
                    varying complexities, power, and speed, we have helped
                    organizations across the globe develop aerospace,
                    automotive, AI, 5G, RAM, memory, industrial, medical,
                    consumer, smart home, and data center chip design.
                  </p>
                  <button className="text-sm border-2 font-semibold border-primary text-primary hover:text-white hover:bg-primary transition duration-300 px-6 py-2">
                    <Link to={"/about"}>ABOUT US</Link>
                  </button>
                </div>
              </div>
            </div>
            <div className="md:w-[50%] py-10 lg:p-10 bg-primary">
              <div className="p-5 flex justify-center items-center flex-col">
                <div className="text-3xl tracking-widest font-heading text-white text-center my-4">
                  BUSINESS METRICS
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {gridData.map((item, index) => (
                    <div
                      className="text-white rounded my-6 text-center"
                      key={index}
                    >
                      <div>
                        <Counter number={item.value} />
                      </div>
                      <div className="text-lg md:text-xl">{item.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="py-10 lg:p-10 bg-white ">
            <div className="p-5 lg:p-[4%]">
              <div className="text-3xl md:text-4xl lg:text-5xl font-heading italic">
                OUR VALUABLE PARTNERS
              </div>

              <div className="border-2 border-gray-400 w-[30%] md:w-[20%] lg:w-[15%] rounded my-6"></div>
              <div className="leading-7 font-medium">
                <p>
                  We have delivered to the largest clients on their latest and
                  technically advanced projects in core industries like
                  Aerospace, Automotive, Defence, Aerospace, Automotive, AI, 5G,
                  RAM.
                </p>
                <p>
                  Memory, Industrial, Medical, Consumer, Smart home and Data
                  centres.
                </p>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 lg:pr-[6%] lg:py-4 lg:gap-8 gap-2 bg-white">
                {numbersArray.map((number) => (
                  <div
                    key={number}
                    className="flex justify-center items-center p-10 w-[180px] h-[140px] hover:shadow-lg transition duration-300 hover:scale-110"
                  >
                    <img
                      className="w-auto h-auto"
                      src={`./assets/${number}.svg`}
                      alt={`${number}`}
                      width={80}
                      height={150}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="py-10 lg:p-10 bg-gray-100 ">
            <div className="p-5 lg:p-[4%]">
              <div className="text-3xl md:text-4xl lg:text-5xl font-heading italic">
                SERVICES WE OFFER
              </div>

              <div className="border-2 border-gray-500 w-[30%] md:w-[20%] lg:w-[15%] rounded my-6"></div>
              <div className="leading-7 font-medium">
                <p>
                  We offer Solutions & Services in niche areas of ASIC/SoC,
                  Embedded Product Engineering supported by Machine Learning,
                  Industrial IoT and Artificial Intelligence.
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 p-6">
                {cardArray.map((card) => (
                  <Homecard key={card.url} data={card} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
