import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PageHeaders from "../components/PageHeaders";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div>
        <PageHeaders url="./assets/Banner.png" title="About Us" />
        <section>
          <div className="flex flex-col md:flex-row justify-center items-center max-w-[1200px] mx-auto gap-10 p-5 md:p-10 my-10">
            <div className="lg:w-[50%] ">
              <img
                className="rounded-lg"
                src="/assets/Chip-design.png"
                alt=""
              />
            </div>
            <div className="lg:w-[50%] w-full">
              <h2 className="font-heading md:text-4xl text-xl mb-2">
                We manage implementation, so you can focus on your dreams
              </h2>
              <div className="leading-7">
                <p>
                  Blueberry Semiconductors Pvt. Ltd. is a Design Service Startup
                  founded by Passionate Entrepreneurs with collective experience
                  of 100+ years. We provide Solution & Service in niche areas of
                  ASIC/SoC, Embedded Product Engineering empowered by Machine
                  Learning, Industrial IoT and Artificial Intelligence.
                </p>
                <p className="my-5">
                  Our business model is flexible supporting right from Staff
                  augmentation, Onsite & Offsite ODC, to BoT
                  (Build-Operate-Transfer) model. Our current engagements with
                  top 5 customers is 2+ years old, which is the best customer
                  testimony we have.
                </p>
                <p>
                  The founders of Blueberry Semiconductors have proven track
                  record of incubating & growing customer account, business
                  verticals, Start-up organization. Orange Semiconductors was
                  the previous successful startup by same founders which focused
                  on VLSI services and Home Automation IoT which was acquired by
                  an Indian product company, Moschip.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-100">
          <div className="flex flex-col md:flex-row justify-center items-center max-w-[1200px] mx-auto gap-10 p-5 md:p-10 my-10 text-center md:text-left">
            <div className="w-full lg:w-[50%] flex flex-col items-center md:block">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-heading">
                Our Mission
              </h2>
              <div className="border-2 border-gray-400 w-[20%] lg:w-[10%] rounded my-2 md:my-4"></div>
              <p className="my-3 md:my-5 font-heading text-xl md:2xl font-bold">
                “Multiply the turnover 5x in next three year”
              </p>
              <p className="leading-7">
                Since inception 3 years back, we have achieved building a
                stable, consistently growing start-up company crossing a revenue
                of 10 Cr. Now is the phase to accelerate growth. We plan to
                achieve this by means of well-planned organic growth and by
                inorganic growth as well.
              </p>
            </div>
            <div className="relative w-[90%] lg:w-[50%]">
              <div className="absolute inset-0 bg-gray-400 opacity-40 rounded shadow-lg transform translate-x-3 translate-y-3"></div>

              <img
                src="/assets/Chip-design.png"
                alt="Your Image"
                className="relative z-10 rounded"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center max-w-[1200px] mx-auto gap-10 p-5 md:p-10 my-10 text-center md:text-left">
            <div className="w-full lg:w-[50%] flex flex-col items-center md:block">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-heading">
                Our Vision
              </h2>
              <div className="border-2 border-gray-400 w-[20%] lg:w-[10%] rounded my-2 md:my-4"></div>
              <p className="my-3 md:my-5 font-heading text-xl md:2xl font-bold">
                “Simulate future with Innovation and be one step ahead of
                customer's expectations.”
              </p>
              <p className="leading-7">
                Working “Smart Way” is the culture we wish to cultivate within
                the organisation and encourage applying innovation in anything &
                everything we do. In today’s scenario where complexity of the
                chip and the product being developed has scaled up in non-linear
                fashion, the cycle time however has not changed, but rather it
                has shortened. Therefore, there is strong need of creating
                innovative solution, and develop intuitive tools to meet
                challenges.
              </p>
            </div>
            <div className="w-full lg:w-[50%] flex flex-col items-center md:block">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-heading">
                Our Goal
              </h2>
              <div className="border-2 border-gray-400 w-[20%] lg:w-[10%] rounded my-2 md:my-4"></div>
              <p className="my-3 md:my-5 font-heading text-xl md:2xl font-bold">
                "Our Goal is to work relentlessly for success of our customer."
              </p>
              <p className="leading-7">
                We would like own the implementation so that our Customer can
                focus on their next dream design. The practice we have been
                following is to quickly identify customer’s critical needs and
                put together the right team in shortest possible time. This is
                the “Mantra” of our success.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
