import { useState } from "react";
import Footer from "../components/Footer";
import ImageOpenModal from "../components/ImageOpenModal";
import Navbar from "../components/Navbar";
import PageHeaders from "../components/PageHeaders";

const LifeBlueBerry = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [isModelOpen, setisModelOpen] = useState(false);

  const handleSelectImage = (e: any) => {
    if (e.target.tagName == "IMG" && e.target?.src) {
      setSelectedImage(e.target.src);
      setisModelOpen(true);
    }
  };
  return (
    <>
      <Navbar />
      <PageHeaders
        url="/assets/Career-background.jpg"
        title="Why Join Blueberry ?"
        desc="Fun-filled learning for engineers in an informal, flexible work environment"
      />
      <div className="py-20 max-w-[1200px] flex flex-col gap-10 m-auto min-h-[60vh] px-4">
        <div className="w-full flex flex-col md:flex-row justify-center items-start gap-5">
          <div className="w-[95%] md:w-1/2 flex flex-col gap-2">
            <h2 className="font-heading text-3xl md:text-4xl">
              Rapidly Growing Company
            </h2>
            <p className="font-montserrat text-[15px] sm:text-[16px]">
              Blueberry is a consistently growing design service company started
              by Founders who are are in Semiconductor Industry since 25+ years.
              Blueberry is focused on only Blueberry is focused on only
              Semiconductor Services & Niche Software (AI-ML) solutions. In
              recent quarters our growth is steep with the help of 20+ Active
              Direct Customers world-wide & strong customer base in Bangalore,
              Hyderabad, Pune, Nagpur & Ahmedabad.
            </p>
          </div>
          <div className="w-[95%] md:w-1/2">
            <img
              src="/assets/1-1.jpeg"
              alt="life blueberry page image"
              className="w-[95%] md:w-[570px] md:h-[320px]"
            />
          </div>
        </div>

        <div className="w-full ">
          <h2 className="font-heading text-3xl md:text-4xl">
            Work Culture and Life @ Blueberry
          </h2>
          <p className="font-montserrat text-[15px] sm:text-[16px]">
            At Blueberry Semiconductors Pvt. Ltd., an atmosphere of
            collaboration and information sharing is encouraged. A relaxed,
            stress-free work environment enables us to focus our energies
            towards our goals. <br /> <br /> Open door policy of Blueberry
            Semiconductors Pvt. Ltd. reduces communication barriers within the
            organization. The management is very approachable and eager to
            connect and resolve any individual issues. Fresh thoughts and
            suggestions are welcomed, discussed and considered. <br /> <br />{" "}
            ‘We can and together we will’ remains our mantra. Teams complement
            individual strengths and strive to achieve desired results.
            Proactive, responsible and accountable individual efforts are
            appreciated and encouraged. Excellence is encouraged and expected
            from all employees. Fun@work – link… (Along the work, we also let
            loose and enjoy sports events, birthday bash, team outings and
            Family Day)
          </p>
        </div>

        <div className="w-full ">
          <h2 className="font-heading text-3xl md:text-4xl">
            Guarenteed Career Acceleration
          </h2>
          <p className="font-montserrat text-[15px] sm:text-[16px]">
            Blueberry provide continuous client projects to talented engineers.
            Skill enhancement is key requirement for steep career growth and
            Blueberry addressed same with the help of inhouse Mentoring, Skill
            Enhancement training programs and opportunity to sharpen soft
            skills. Blueberry does not do layoffs. Blueberry firmly believes
            that all engineers in India are talented, who just need to discover
            their own strength areas. At Bluberry you will find flat reporting
            structure with CEO accessible anytime, and awards & bonus for
            exceptional performance.
          </p>
        </div>

        <div className="w-full ">
          <h2 className="font-heading text-3xl md:text-4xl">
            Career Progression
          </h2>
          <p className="font-montserrat text-[15px] sm:text-[16px]">
            Blueberry believes in providing fast track career progression to the
            employees. Highly motivated and deserving employees scale the growth
            levels at a higher pace. The progression happens purely on the basis
            of contribution, performance, skill capabilities and experienced
            gain keeping in mind the potential of an individual to grow and
            handle the next level responsibilities.
          </p>
        </div>

        <div className="w-full ">
          <h2 className="font-heading text-3xl md:text-4xl">
            Work Environment
          </h2>
          <p className="font-montserrat text-[15px] sm:text-[16px]">
            Blueberry is an equal opportunity employer and it appreciates the
            efforts of its employees and strives to provide them with the best
            facilities and benefits equal or better than most of the services
            and product companies. Stress free employees can focus better and
            achieve their potential paving the path for success.
          </p>
        </div>

        <div className="w-full ">
          <h2 className="font-heading text-3xl md:text-4xl">
            Standard Benefits
          </h2>
          <ol className="font-montserrat list-disc ml-9 text-[15px] sm:text-[16px]">
            <li>Excellent opportunity to work with the best talent</li>
            <li>Best in class compensation</li>
            <li>Annual paid vacation</li>
            <li>Motivational incentive plans</li>
            <li>Group Mediclaim Insurance</li>
            <li>Recreational facilities</li>
            <li>Company outings, get-togethers and dinners</li>
            <li>Work from home facilities</li>
            <li>Regular professional and personal growth training</li>
            <li>State of the art facilities for work</li>
          </ol>
        </div>

        <div
          className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
          onClick={handleSelectImage}
        >
          <img
            src="/assets/blueberry/10-1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/9-1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-2"
          />
          <img
            src="/assets/blueberry/11-1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/8-1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/7-1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/6-1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/5-2.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-2"
          />
          <img
            src="/assets/blueberry/4-1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/3-2.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-2"
          />
          <img
            src="/assets/blueberry/2-2.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/1-2.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/1_3_6.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/2_3.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/3_1.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-2"
          />
          <img
            src="/assets/blueberry/4_6.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
          <img
            src="/assets/blueberry/5_3.jpeg"
            className="h-[232px] cursor-pointer hover:scale-[1.1] transition:all duration-[0.5s] w-full object-cover xl:col-span-1"
          />
        </div>

        {isModelOpen && (
          <ImageOpenModal
            selectedImage={selectedImage}
            modalIsOpen={isModelOpen}
            handleModelOpen={setisModelOpen}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default LifeBlueBerry;
