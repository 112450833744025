import Footer from "../components/Footer";
import PageHeaders from "../components/PageHeaders";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import SingleJobModelBox from "../components/SingleJobModelBox";
import { jobsTitles } from "../constants";

type Props = {};

const VlsiPage = (props: Props) => {
  return (
    <>
      <Navbar />
      <PageHeaders
        url="/assets/Career-background.jpg"
        title="Jobs & Openings"
      />
      <section>
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start max-w-[1200px] mx-auto gap-6 p-5 md:p-10 my-10">
          <div className="w-full h-fit grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 place-items-center">
            {jobsTitles.map((ele, ind) => (
              <SingleJobModelBox key={ind} title={ele.title} url={ele.url} />
            ))}
          </div>
          <div className="w-[95%] sm:w-[380px] min-h-[50vh] flex flex-col gap-1">
            <div className="w-full h-fit flex flex-col gap-1">
              <Link
                to={"/careers/jobs/vlsi"}
                className="h-[60px] bg-[#045e9a] px-4 flex justify-start items-center text-[17.5px] text-white"
              >
                <p className="font-montserrat">VLSI</p>
              </Link>
              <Link
                to={"/careers/jobs/software-embedded"}
                className="h-[60px] bg-[#045e9a] px-4 flex justify-start items-center text-[17.5px] text-white"
              >
                <p className="font-montserrat">Software and Embedded</p>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default VlsiPage;
